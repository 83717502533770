import React, { useState, useEffect } from 'react';
import { Collapse, Container, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink } from 'reactstrap';
import { Redirect, Link } from 'react-router-dom';
import './NavMenu.css';
import { API_URL } from './constant/URL';
import axios from 'axios';


export default function NavMenu(props) {
    const [IsAdmin, setIsAdmin] = useState(false);

    const [RejectedWordsCount, setRejectedWordsCount] = useState(0);
    const GetAccess = async (access_level) => {
            if (access_level) {
                var email_id = props.token != null && props.token.email != null ? props.token.email : "";
                if (email_id != "") {
                    axios(`${API_URL}` + 'Account/GetAccess?email=' + email_id)
                        .then((result) => {
                            setIsAdmin(result.data);
                        });
                }
            }
            else {
                setIsAdmin(false);
            }

    };

    const GetRejectedWordsCount = async () => {
        var id = props.token != null && props.token.id != null ? props.token.id : "";
        if (id != "") {
            axios(`${API_URL}` + 'Aayaat/GetRejectedWordsCount?uid=' + id)
                .then((result) => {
                    if (result.data) {
                        setRejectedWordsCount(result.data);
                    }
                });
        }

    };
    useEffect(() => {
        GetAccess(true);
        GetRejectedWordsCount();
    }, [props.token]);
    
    const logout = () => {
        localStorage.token = null;
        GetAccess(false);
        window.location.href = '/';
    }
  
    return (props.token != null && props.token.email != undefined) ? (
        <header>
            <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3 " style={{ backgroundColor: '#548ebc' }} light>
                <Container>
                    <NavbarBrand style={{ color: 'white', fontWeight: 'bold' }} tag={Link} to="/aayaat/aayat-list">Easy Quranic Arabic Admin Site</NavbarBrand>

                        <ul className="navbar-nav flex-grow">
                            {IsAdmin ? <NavItem>
                                <NavLink tag={Link} to="/aayaat/review-list" className="text-dark pl-0">Review |</NavLink>
                            </NavItem> : <></>}
                            <NavItem>
                            <NavLink tag={Link} to="/aayaat/my-edits" className="text-dark pl-0">My Edits <span style={{ position: 'relative' }} ><span className='notification-circle'>{RejectedWordsCount}</span></span>|</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/aayaat/aayat-list" className="text-dark pl-0">Quran |</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/UserManagement/User" className="text-dark pl-0">Users | </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink tag={Link} to="/BaabManagement/Baab" className="text-dark pl-0">Baab | </NavLink>
                            </NavItem>
                            <NavItem>
                                <span className="text-dark" style={{ lineHeight: 2.5 }}>Welcome <b>{props.token.email}</b> | </span>
                            </NavItem>

                            <NavItem>
                                <NavLink tag={Link} to="javascript:void(0);"className="text-dark" onClick={logout}>Logout</NavLink>
                            </NavItem>
                        </ul>
                </Container>
            </Navbar>
        </header>
    ) : (<header>
        <Navbar className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3" style={{ backgroundColor: '#548ebc' }} light>
            <Container>
                <NavbarBrand style={{ color: 'white', fontWeight: 'bold' }} >Easy Quranic Arabic Admin Site</NavbarBrand>
            </Container>
        </Navbar></header>);
  
};
 