import React, { useState,Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
class ProtectedRoute extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const Component = this.props.component;
        const isAuthenticated = localStorage.getItem("isAuthenticated");

        return this.props.token===true ? (
            <Component />
        ) : (
                <Redirect to={{ pathname: '/login' }} />
            );
    }
}
export default ProtectedRoute;

//export default function Login({ token }) {
//    return token === true ? (
//        <Component />
//    ) : (
//            <Redirect to={{ pathname: '/login' }} />
//        );

//}