import React from 'react'
import { Card, CardBody, CardHeader, Col, Pagination, PaginationItem, PaginationLink, Row, Table, Input, InputGroup } from 'reactstrap';
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Button, Spinner } from 'react-bootstrap';
import { render } from "react-dom";
import ReactTable from '../Table/ReactTable.js';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { API_URL } from '../constant/URL';
//import "react-table/react-table.css";
import EqaContextMenu from '../ContextMenu/EqaContextMenu';
import NahvLegend from '../ContextMenu/NahvLegend';
import EqaHelper from '../Helper/EqaHelper.js';
import Message from '../constant/Message';
import FileUpload from './FileUpload.js';
import Ishaaraat from '../Ishaaraat/Ishaaraat.js';
import AuthContext from '../Login/AuthContext';

var surahObj = [];

function ReviewList(props) {
    const { token, setToken } = AuthContext();
    const [aayaat, setAayat] = useState([]);
    const [pages, setPages] = useState([]);
    const [words, setWords] = useState([]);
    const [word, setWord] = useState([]);
    const [surah, setSurah] = useState([]);
    //const [surahObj, setSurahObj] = useState([]);
    const [wordCount, setWordCount] = useState([]);
    const [sarfFormData, setSarfFormData] = useState([]);
    const [surahayatNum, setSurahayatNum] = useState({ surahNum: 0, ayatNum: 0 });
    const [translation, setTranslation] = React.useState({ englishTranslation: "", urduTranslation: "" });
    const [wordGrammerData, setWordGrammerData] = useState([]);
    const [reviewComments, setReviewComments] = useState('');

    //const [updatedData, setupdatedData] = React.useState({
    //    masdar: "", mafool: "", faail: "", amar: "", muzaaray: "", maazi: "", ism_e_mubaalgha: "", seegha_mushabba: "", baab_code: ""
    //    //,is_grammer_data: true
    //});
    let updatedWordsData = [];
    const updatedTranslation = [];
    const options = [];

    const [selectedOption, setSelectedOption] = useState(2);
    const [selectedSurah, setSelectedSurah] = useState(1);
    const [selectedSurahName, setSelectedSurahName] = useState("1 - سورة الفاتحة");
    //const [selectedBaab, setSelectedBaab] = useState();
    const [extraFields, setExtraFields] = React.useState({ extra_field1: "", extra_field2: "" });
    const [wordsBeforeEdit, setWordsBeforeEdit] = useState([]);
    const [showComparison, setShowComparison] = useState(false);
    const [wordBeforeEdit, setWordBeforeEdit] = useState([]);
    const [wordGrammerDiff, setWordGrammerDiff] = useState([]);
    const [extraFieldsBeforeEdit, setExtraFieldsBeforeEdit] = React.useState({ extra_field1: "", extra_field2: "" });

    const [selectedDDLValues, setSelectedDDLValues] = useState({
        pos3_value: "0",
        ishaara_picture_value: "0",
        gender_value: "0",
        number_value: "0",
        word_state_value: "0",
        tense: "0",
        noun_type_id: "0",
        //baab_code2: "0",
        baab_code: "0",
        traditional_baab_id: "0",
    });

    const refreshWfwDialogData = function () {
        setWord([]);
        setWordGrammerData([]);
        setWordBeforeEdit([]);
        setWordGrammerDiff([]);
        updatedWordsData = [];//TODO
        setExtraFields({ extra_field1: "", extra_field2: "" });
        setExtraFieldsBeforeEdit({ extra_field1: "", extra_field2: "" });
        //editAyatWords(surahayatNum.ayatNum, surahayatNum.surahNum);
    }

    const [blocking, setBlocking] = useState([false]);
    //console.log("sarfFormData:",sarfFormData.lstBaab);
    const AlMushafFont = {
        fontFamily: "AlMushaf",
        fontSize: 28
    };
    const Majidi_v5Font = {
        fontFamily: "Majidi_v5",
        fontSize: 28
    };
    const Majidi_v5FontSmall = {
        fontFamily: "Majidi_v5",
        fontSize: 20
    };
    const AlviFont = {
        fontFamily: "Alvi",
        fontSize: 24
    };
    const filterBaabs = baabItem => {
        //return surahItem >= 18;
        //console.log("baabItem:", baabItem);
        //console.log("selectedDDLValues.traditional_baab_id:", selectedDDLValues.traditional_baab_id);
        return Number(baabItem.additionalInformation) == Number(selectedDDLValues.traditional_baab_id) ? true : false;
        //console.log('surahItem:', surahItem.num);
        //return true;
    };
    useEffect(() => {
        const GetPages = async () => {
            axios(`${API_URL}` + 'Aayaat/GetPages/')
                .then((result) => {
                    const options = result.data.map(pagenum => ({
                        "value": pagenum,
                        "label": pagenum
                    }))
                    setPages(options);
                    getSurahList();
                    getAyaatList(result.data[0]);
                });

        };
        GetPages();
    }, []);
    
    const getSurahList = () => {
        axios(`${API_URL}` + 'Aayaat/GetSurahs/')
            .then((result) => {

                const key = 'num';
                const unique = [...new Map(result.data.map(item =>
                    [item[key], item])).values()];
                const options = unique.map(surah => ({
                    "value": surah.num,
                    "label": surah.arabic_name
                }))
                setSurah(options);
                for (var i = 0; i < result.data.length; i++) {
                    surahObj.push(result.data[i]);
                }
                //setSurahObj(result.data);
            });
    };
    const columns = React.useMemo(
        () => [
            //{
            //    Header: 'Id',
            //    accessor: 'id',
            //},
            {
                Header: 'ماضي',
                accessor: 'maazi',
            },
            {
                Header: 'مضارع',
                accessor: 'muzaaray',
            },
            {
                Header: 'امر',
                accessor: 'amar',
            },
            {
                Header: 'اسم فاعل',
                accessor: 'faail',
            },
            {
                Header: 'مفعول',
                accessor: 'mafool',
            },
            {
                Header: 'مصدر',
                //ماضي	مضارع	امر	اسم فاعل	اسم مفعول	مصدر	اسم مبالغہ	صفت مشبہ
                accessor: 'masdar',
            },
            {
                Header: 'اسم مبالغہ',
                accessor: 'ism_e_mubaalgha',
            },
            {
                Header: 'صفت مشبہ',
                accessor: 'seegha_mushabba',
            },
            //    {
            //        Header: 'Baab Code',
            //        accessor: 'baab_code',
            //    },
        ],
        []
    )
    //const translationcolumns = React.useMemo(
    //    () => [

    //        {
    //            Header: 'English Translation',
    //            accessor: 'englishTranslation',
    //        },
    //        {
    //            Header: 'Urdu Translation',
    //            accessor: 'urduTranslation',
    //        },

    //    ],
    //    []
    //)
    const getAyaatList = (pageNum) => {
        if (pageNum == 2) {
            document.getElementById("PrevBtn").style["pointerEvents"] = "none";
            document.getElementById("PrevBtn").style["color"] = "grey";
        }
        else {
            document.getElementById("PrevBtn").style["pointerEvents"] = "";
            document.getElementById("PrevBtn").style["color"] = "#0366d6";
        }
        if (pageNum == 611) {
            document.getElementById("NextBtn").style["pointerEvents"] = "none";
            document.getElementById("NextBtn").style["color"] = "grey";
        }
        else {
            document.getElementById("NextBtn").style["pointerEvents"] = "";
            document.getElementById("NextBtn").style["color"] = "#0366d6";
        }
        setBlocking(true);
        var id = token != null && token.id != null ? token.id : "";
        axios(`${API_URL}` + 'Aayaat/GetEditAayaat?uid=' + id)
            .then((result) => {
                if (result.data != null && result.data.response != null && result.data.response == "Forbidden") {
                    setTimeout(function () { setBlocking(false); }, 1000);
                    window.location.href = '/';
                }
                else {
                    setAayat(result.data);
                    setTimeout(function () { setBlocking(false); }, 1000);
                }
                

            });
    };

    const getSarfFormData = () => {
        setBlocking(true);
        axios(`${API_URL}` + 'Aayaat/GetSarfFormData')
            .then((result) => {
                //TODO
                // setAayat(result.data);
                // setTimeout(function () { setBlocking(false); }, 1000);
                //console.log("getSarfFormData > result.data:", result.data);                
                setSarfFormData(result.data);
                //console.log("getSarfFormData > sarfFormData:", sarfFormData);
            });
    };


    const onSubWordsModelUpdate = (paramSubwords) => {
        //debugger;
        let word_type = "S";
        if (paramSubwords == null) {
            word_type = "W";
        }
        //TODO
        setWord(prevState => ({
            ...prevState,
            sub_words: paramSubwords,//'{"word": "اَلْ" , "id":"1", "category": "afaal"},{  "category": "afaal", "word":"حَمْدُ" , "id":"2"}'
            word_type: word_type,
        }));
    };

    const updateRecord = (isApproved) => {
        try {
            setBlocking(true);
            const reviewData = {
                wordId:"",
                isApproved: "", reviewComments: ""
                
            };
            //debugger;

            reviewData.wordId = word.wordId;
            reviewData.isApproved = isApproved;
            reviewData.reviewComments = reviewComments;
            reviewData.user_id = token != null && token.email != null ? token.email : "";
            reviewData.user_identity_id = token != null && token.id != null ? token.id : "";
            reviewData.is_save_all=word.is_save_all
           
            let reviewGrammarUrl = `${API_URL}` + 'Verb/ReviewGrammar/';
            
            axios.post(reviewGrammarUrl, reviewData)

                .then((result) => {
                    if (result.data)
                        toast.success('Review is saved successfully.');
                    else
                        toast.error('Failed to save the review.');

                    setTimeout(function () { setBlocking(false); }, 1500);
                    editAyatWords(surahayatNum.ayatNum, surahayatNum.surahNum);
                    props.history.push('/aayaat/review-list');
                });
            

        } catch (e) {
            setBlocking(false);
            toast.error('Error: ' + e);
        }

    }

    const updateTranslation = () => {
        try {
            // if (translation.length > 0) {
            translation.surahNum = surahayatNum.surahNum;
            translation.ayatNum = surahayatNum.ayatNum;
            setBlocking(true);

            axios.post(`${API_URL}` + 'Aayaat/UpdateWordTranslation/', translation)
                .then((result) => {
                    if (result.data)
                        toast.success('Word data is updated successfully.');
                    else
                        toast.error('Failed to update word data.');


                    setTimeout(function () { setBlocking(false); }, 1500);


                    props.history.push('/aayaat/aayat-list');
                });
            //}
            // else {
            //    return false;
            // }

        } catch (e) {
            toast.error('Error: ' + e);
        }

    }
    //const handleChange = (event) => {
    //    setSelectedOption(event.value);
    //    var sObj = surahObj.find(a => a.starting_page_num == event.value);
    //    if (sObj) {
    //        setSelectedSurah(sObj.num);
    //        setSelectedSurahName(sObj.arabic_name);
    //    }
    //    else {
    //        sObj = surahObj.find(a => a.starting_page_num <= event.value && a.ending_page_num >= event.value);
    //        setSelectedSurah(sObj.num);
    //        setSelectedSurahName(sObj.arabic_name);
    //    }
    //    getAyaatList(event.value);      
    //    setWords([]);
    //};

    const OpenNextPage = (page) => {
        const pgNo = page + 1;
        setSelectedOption(pgNo);
        var sObj = surahObj.find(a => a.starting_page_num == pgNo);
        if (sObj) {
            setSelectedSurah(sObj.num);
            setSelectedSurahName(sObj.arabic_name);
        }
        else {
            sObj = surahObj.find(a => a.starting_page_num <= pgNo && a.ending_page_num >= pgNo);
            setSelectedSurah(sObj.num);
            setSelectedSurahName(sObj.arabic_name);
        }
        getAyaatList(pgNo);
    };

    const OpenPreviousPage = (page) => {
        const pgNo = page - 1;
        setSelectedOption(pgNo);
        var sObj = surahObj.find(a => a.starting_page_num == pgNo);
        if (sObj) {
            setSelectedSurah(sObj.num);
            setSelectedSurahName(sObj.arabic_name);
        }
        else {
            sObj = surahObj.find(a => a.starting_page_num <= pgNo && a.ending_page_num >= pgNo);
            setSelectedSurah(sObj.num);
            setSelectedSurahName(sObj.arabic_name);
        }
        getAyaatList(pgNo);
    };

    const PageMenuOpen = () => {
        setTimeout(() => {
            const selectedEl = document.getElementsByClassName("PageDropdown__option--is-selected")[0];
            if (selectedEl) {
                selectedEl.scrollIntoView({ behavior: 'smooth' });
            }
        }, 15);
    };

    const SurahMenuOpen = () => {
        setTimeout(() => {
            const selectedEl = document.getElementsByClassName("SurahDropdown__option--is-selected")[0];
            if (selectedEl) {
                selectedEl.scrollIntoView({ behavior: 'smooth' });
            }
        }, 15);
    };

    const handleSurahChange = (event) => {
        var sObj = surahObj.find(a => a.num == event.value);
        setSelectedSurah(sObj.num);
        setSelectedSurahName(sObj.arabic_name);
        var pNum = surahObj.find(a => a.num == event.value).starting_page_num;
        setSelectedOption(pNum);
        getAyaatList(pNum);       
        setWords([]);
    };

    //DDL events 
    const handlePos3DDLChange = (event) => {
        setSelectedDDLValues({ ...selectedDDLValues, pos3_value: event.target.value });
    };
    const handleIsharaDDLChange = (event) => {
        setSelectedDDLValues({ ...selectedDDLValues, ishaara_picture_value: event.target.value });
    };
    const handleGenderDDLChange = (event) => {
        setSelectedDDLValues({ ...selectedDDLValues, gender_value: event.target.value });
    };
    const handleNumberDDLChange = (event) => {
        setSelectedDDLValues({ ...selectedDDLValues, number_value: event.target.value });
    };
    const handleWordStateDDLChange = (event) => {
        setSelectedDDLValues({ ...selectedDDLValues, word_state_value: event.target.value });
    };
    const handleTenseDDLChange = (event) => {
        setSelectedDDLValues({ ...selectedDDLValues, tense: event.target.value });
    };
    const handleNounTypeDDLChange = (event) => {
        setSelectedDDLValues({ ...selectedDDLValues, noun_type_id: event.target.value });
        if (event.target.value == '9') {
            setTimeout(() => {
               
                const JMLabel = document.getElementById("JMLabel");
                if (JMLabel) {
                    JMLabel.style["display"] = 'block';
                }
                const JMInput = document.getElementById("JMInput");
                if (JMInput) {
                    JMInput.style["display"] = 'block';
                }
            }, 15);
        }
        else {
            setTimeout(() => {
                
                const JMLabel = document.getElementById("JMLabel");
                if (JMLabel) {
                    JMLabel.style["display"] = 'none';
                }
                const JMInput = document.getElementById("JMInput");
                if (JMInput) {
                    JMInput.style["display"] = 'none';
                }
            }, 15);
        }
    };
    const handleTraditionalBaabDDLChange = (event) => {
        if (selectedDDLValues.traditional_baab_id == 0) {
            setSelectedDDLValues({ ...selectedDDLValues, baab_code: "0", traditional_baab_id: event.target.value });
        }
        else {
            setSelectedDDLValues({ ...selectedDDLValues, traditional_baab_id: event.target.value });
        }
    };
    const handleBaabCodeDDLChange = (event) => {
        console.log("handleBaabCodeDDLChange > event.target.value:", event.target.value, " selectedDDLValues:", selectedDDLValues);
        setSelectedDDLValues({ ...selectedDDLValues, baab_code: event.target.value });
    };
    //eof DDL events 

    const handleTranslationChange = (e, trans) => {
        e.persist();
        setTranslation({ ...trans, [e.target.name]: e.target.value });
    };
    const handleWordChange = (e, word) => {
        e.persist();
        //setupdatedData(wordGrammerData);
        setWord({ ...word, [e.target.name]: e.target.value });
    };
    const handleExtraFieldsChange = (e, option) => {
        e.persist();
        //debugger;
        if (option == "1") {
            setExtraFields({ ...extraFields, extra_field1: e.target.value });
        } else if (option == "2") {
            setExtraFields({ ...extraFields, extra_field2: e.target.value });
        }
    };
    const editAyatWords = (ayatNum, surahNum) => {
        axios.get(`${API_URL}` + 'Aayaat/GetEditWords', {
            params: {
                surahNum: surahNum,
                ayatNum: ayatNum
            }
        }).then((result) => {
            setWords(result.data);
            if (result.data.length < 1) {              
               getAyaatList(0);
            }
            setTimeout(function () { setBlocking(false); }, 1500);
            //document.getElementById("wfwTable").scrollIntoView({
            //    behavior: 'smooth'
            //});
            const wfwtbl = document.getElementById("wfwTable");
            if (wfwtbl) {
                wfwtbl.scrollIntoView({ behavior: 'smooth' });
            }
        });
    }
    const ayatWordsBeforeEdit = (ayatNum, surahNum) => {
        axios.get(`${API_URL}` + 'Aayaat/GetWordsBeforeEdit', {
            params: {
                surahNum: surahNum,
                ayatNum: ayatNum
            }
        }).then((result) => {
            setWordsBeforeEdit(result.data);
            setTimeout(function () { setBlocking(false); }, 1500);
        });
    }
    const editAayat = (event, ayatNum, surahNum, pageNum) => {
        //surahayatNum.ayatNum = ayatNum;
        //surahayatNum.surahNum = surahNum;
        setSurahayatNum({ surahNum: surahNum, ayatNum: ayatNum });
        setBlocking(true);
        var selectedAyatRow = document.getElementsByClassName("selectedrow")[0];
        if (selectedAyatRow != undefined)
            selectedAyatRow.classList.remove("selectedrow");

        event.target.parentElement.parentElement.parentElement.className = "selectedrow";
        editAyatWords(ayatNum, surahNum);
        ayatWordsBeforeEdit(ayatNum, surahNum);
        axios.get(`${API_URL}` + 'Aayaat/GetTranslation', {
            params: {
                surahNum: surahNum,
                ayatNum: ayatNum
            }
        }).then((result) => {
            setTranslation(result.data[0]);
        });
        //axios.get(`${API_URL}` + 'Ishaaraat/GetIshaaraat', {
        //    params: {
        //        pageNum: pageNum
        //    }
        //}).then((result) => {
        //    setIshaaraats(result.data);
        //    setAddPointer({ ...addPointer, para: Number(result.data[0].para) });
        //    paraNo = parseInt(result.data[0].para);
        //    setAddPointer({ ...addPointer, lang_id: parseInt(result.data[0].lang_id) });
        //});
        //axios(`${API_URL}` + 'Ishaaraat/GetSurah/', {
        //    params: {
        //        pageNum: pageNum
        //    }
        //}).then((result) => {
        //    var unique = result.data.filter(onlyUnique);
        //    const options = unique.map(surah => ({
        //        "value": surah,
        //        "label": surah
        //    }))
        //    setSurah(options);
        //});
    };
    //const renderEditable = (cellInfo) => {
    //    debugger;
    //    return (
    //        <div
    //            style={{ backgroundColor: "#fafafa" }}
    //            contentEditable
    //            suppressContentEditableWarning
    //            onBlur={e => {
    //                const data = [...wordGrammerData];
    //                data[cellInfo.index][cellInfo.column.id] = e.target.innerHTML;
    //                //data[cellInfo.index].full = data[cellInfo.index].age * 2;
    //                //this.setState({ data });
    //            }}
    //            dangerouslySetInnerHTML={{
    //                __html: wordGrammerData[cellInfo.index][cellInfo.column.id]
    //            }}
    //        />
    //    );
    //};
    const undoWordEdits = (wordId) => {
        if (window.confirm("Are you sure you want to undo edits for this word?")) {
            axios(`${API_URL}` + 'Aayaat/undoWordEdits?wordId=' + wordId)
                .then((result) => {
                    editAyatWords(surahayatNum.ayatNum, surahayatNum.surahNum);
                });
        }
    };

    //const highlightDifferences = ()
    const getWordById = (paramWordId, paramArr) => 
    {
        let foundWord = null;
        for (let i = 0; i < paramArr.length; i++) {
            if (paramArr[i].wordId == paramWordId) {
                foundWord = paramArr[i];
                break;
            }
        }

        return foundWord;
    };

    const editWords = (wordId, item) => {
        getSarfFormData();
        setBlocking(true);
        //item.root_text = EqaHelper.getSpaceSeparatedChars(item.root_text);
        setReviewComments('');
        setWord(item);
        let foundWordBeforeEdit = getWordById(wordId, wordsBeforeEdit);
        if (foundWordBeforeEdit != null)
        {
            setWordBeforeEdit(foundWordBeforeEdit);
        }
        //debugger;
        setSelectedDDLValues({
            ...selectedDDLValues,
            pos3_value: item.pos3_value,
            ishaara_picture_value: item.ishaara_picture_value,
            gender_value: item.gender_value,
            number_value: item.number_value,
            word_state_value: item.word_state_value,
            tense: item.tense,
            noun_type_id: item.noun_type_id,
            //baab_code2: item.baab_code2,
            baab_code: item.baab_code,
            traditional_baab_id: item.traditional_baab_id,
        });

        if (item.noun_type_id == 9) {
            setTimeout(() => {
                
                const JMLabel = document.getElementById("JMLabel");
                if (JMLabel) {
                    JMLabel.style["display"] = 'block';
                }
                const JMInput = document.getElementById("JMInput");
                if (JMInput) {
                    JMInput.style["display"] = 'block';
                }
            }, 15);
        }
        else {
            setTimeout(() => {
                
                const JMLabel = document.getElementById("JMLabel");
                if (JMLabel) {
                    JMLabel.style["display"] = 'none';
                }
                const JMInput = document.getElementById("JMInput");
                if (JMInput) {
                    JMInput.style["display"] = 'none';
                }
            }, 15);
        }
        axios(`${API_URL}` + 'Aayaat/GetWordCount?wordId=' + wordId)
            .then((result) => {
                if (result.data > 0) {
                    setWordCount(result.data);
                    Message.msg_update_all = "Are you sure you want to save the information for all similar count words?";
                    Message.msg_update_all = Message.msg_update_all.replace("count", result.data);
                }
            });
        axios(`${API_URL}` + 'Aayaat/GetWordGrammer?wordId=' + wordId)
            .then((result) => {
                //if (result.data.length > 0) {
                setWordGrammerData(result.data);
                updatedWordsData.push(result.data[0]);
                //verbId = result.data[0].id;
                setExtraFields({ extra_field1: result.data[0].extra_field1, extra_field2: result.data[0].extra_field2 });
                //}
                //else {
                //    updatedWordsData.push({ masdar: "", mafool: "", faail: "", amar: "", muzaaray: "", maazi: "", ism_e_mubaalgha: "", seegha_mushabba: "", baab_code: "", is_grammer_data: false });
                //    setWordGrammerData([{ amar: "", baab_code: "", faail: "", id: 0, is_grammer_data: false, ism_e_mubaalgha: "", maazi: "", mafool: "", masdar: "", muzaaray: "", seegha_mushabba: ""}]);
                //}
                // updatedWordsData.push(result.data[0]);
                //setSelectedBaab(result.data[0].baab_code);
                //setTimeout(function () { setBlocking(false); }, 1500);

            });

        axios(`${API_URL}` + 'Aayaat/GetWordGrammerDifference?wordId=' + wordId)
            .then((result) => {
                setWordGrammerDiff(result.data);
                setExtraFieldsBeforeEdit({ extra_field1: result.data[0].extra_field1, extra_field2: result.data[0].extra_field2 });
                setTimeout(function () { setBlocking(false); }, 1500);
                setShowComparison(true);
            });
    }
    const editRunningTranslation = (translationId) => {
        //axios('https://localhost:44304/api/Aayaat/GetWordGrammer?wordId=' + wordId)
        //    .then((result) => {
        //        debugger;
        //        setWordData(result.data);
        //        updatedWordsData.push(result.data[0]);
        //    });
        // alert(translationId)

    }
    const handleSave = (rowIndex) => {
        const rowChanges = this.state.rowsChanges[rowIndex];
        console.log("Save row changes into db...")
        //clear row changes...
        this.setState(prevState => ({
            rowsChanges: {
                ...prevState.rowsChanges,
                [rowIndex]: {}
            }
        }));
    }
    return (
        <div>


            <BlockUi tag="div" blocking={blocking}>
                <h2>Review List</h2>
                <p className="help-text">This section allows reviewing the word and grammar data edits submitted for review and approval.</p>
                <div className="content" style={{
                    marginBottom: 10
                }}>
                    <div className="row">
                        <div style={{ width: '17%', paddingLeft:'15px'}}>
                            {/*<Select*/}
                            {/*    id="surahSelect"*/}
                            {/*    value={{ label: selectedSurahName, value: selectedSurah }}*/}
                            {/*    className="SurahDropdown"*/}
                            {/*    classNamePrefix="SurahDropdown"*/}
                            {/*    onChange={handleSurahChange}*/}
                            {/*    onMenuOpen={SurahMenuOpen}*/}
                            {/*    options={surah}*/}
                            {/*    menuShouldScrollIntoView={true}*/}
                            {/*/>*/}
                        </div>
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'absolute', left:'50%' }}>
                            <Button hidden id="NextBtn" style={{ height: '30px', marginTop: '-2px', backgroundColor: 'white', border: 'none', outline: 'none', boxShadow: 'none' }} className="navpagebtn" onClick={(event) => { OpenNextPage(selectedOption) }} title="Next page"><i className="fa fa-chevron-left"></i></Button>
                            {/*<Select*/}
                            {/*    id="pageSelect"*/}
                            {/*    value={{ label: selectedOption, value: selectedOption }}*/}
                            {/*    onChange={handleChange}*/}
                            {/*    className="PageDropdown"*/}
                            {/*    classNamePrefix="PageDropdown"*/}
                            {/*    onMenuOpen={PageMenuOpen}*/}
                            {/*    options={pages}*/}
                            {/*    menuShouldScrollIntoView={true}*/}
                            {/*/>*/}
                            <Button hidden id="PrevBtn" style={{ height: '30px', marginTop: '-2px', backgroundColor: 'white', border: 'none', outline: 'none', boxShadow: 'none' }} className="navpagebtn" onClick={(event) => { OpenPreviousPage(selectedOption) }} title="Previous page"><i className="fa fa-chevron-right"></i></Button>

                        </div>                      
                    </div>
                </div>
                <div className="animated fadeIn review-list" >
                    {/*<div className="padding-10">*/}
                    {/*    {*/}
                    {/*        aayaat.map((item, idx) => {*/}
                    {/*            if (idx === 0) {*/}
                    {/*                return <div>*/}
                    {/*                    Surah Number: <span>{item.surahNum}</span>*/}
                    {/*                </div>*/}
                    {/*            }*/}
                    {/*        })*/}
                    {/*    }*/}
                    {/*</div>*/}
                    
                        <div style={{ marginBottom: '10px' }}>
                        <h4>Submitted for Review</h4>
                        {aayaat.length > 0 ?
                            <Row>
                                <Col>
                                    <Card style={{ height: '420px', overflowX: 'auto' }}>
                                        <Table hover bordered striped responsive size="sm" style={{ direction: 'rtl' }}>
                                            <thead>
                                                <tr> 
                                                    <th style={{ textAlign: 'center' }}> Surah No </th>
                                                    <th style={{ textAlign: 'right', width: "150px" }}> Surah Name</th>
                                                    <th style={{ textAlign: 'center' }}> Ayat No </th>
                                                    <th style={{ textAlign: 'right' }}> Aayat </th>
                                                    <th className="width-100"> </th>
                                                </tr>
                                            </thead >
                                            <tbody>
                                                {
                                                    aayaat.map((item, idx) => {
                                                        return <tr id={item.ayatNum} >
                                                            <td style={{ textAlign: 'center' }}><span>{item.surahNum}</span></td>
                                                            <td style={{ textAlign: 'right' }}><span style={Majidi_v5Font}>{item.surahArabicName}</span></td>
                                                            <td style={{ textAlign: 'center' }}><span>{item.ayatNum}</span></td>
                                                            <td style={{ textAlign: 'right' }}><span style={Majidi_v5Font}>{item.ayat}</span></td>
                                                            <td className="align-center">
                                                                <div className="btn-group">
                                                                    <span className="edit-icon" onClick={(event) => { editAayat(event, item.ayatNum, item.surahNum, item.pageNum) }} title={"Edit"}></span>
                                                                    {/*<button className="btn btn-success" onClick={() => { editAayat(item.ayatNum, item.surahNum) }}>Edit</button>*/}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    })
                                                }
                                            </tbody>
                                        </Table>
                                    </Card>
                                </Col>
                            </Row>
                            : <div style={{ backgroundColor: '#f0f0f0', padding: '10px', borderRadius: '5px' }}>
                                Currently, there are no edited aayat.
                            </div>}
                        </div>
                        
                    {words.length > 0 ?
                        <Tabs className="tab-section">
                            <TabList>
                                <Tab name='word_for_word' style={{ fontWeight: 'bold' }}>WFW Translation/Grammar</Tab>
                                
                            </TabList>
                            <TabPanel>
                                <CardBody>
                                    {words.length > 0 ?
                                        <div style={{ marginBottom: '10px' }}>
                                            <p className="help-text">This section allows revewing/editing the word for word translation and grammar data.</p>

                                            <NahvLegend />
                                            <Row>

                                                <Col>
                                                    <Card style={{ height: '300px', overflowX: 'auto' }}>
                                                        <Table hover bordered striped responsive size="sm" id="wfwTable" style={{ marginBottom: 0 }}>
                                                            <thead>
                                                                <tr>
                                                                    <th> Araab Text</th>
                                                                    <th> Urdu Translation</th>
                                                                    <th> English Translation</th>
                                                                    <th className="width-100"> </th>
                                                                </tr>
                                                            </thead >
                                                            <tbody>
                                                                {
                                                                    words.map((item, idx) => {
                                                                        return <tr>
                                                                            <td style={AlMushafFont} className="align-right">
                                                                                <span dangerouslySetInnerHTML={{ __html: EqaHelper.createMarkup(item.araab, item.sub_words) }} />
                                                                            </td>
                                                                            <td style={AlviFont} className="align-right">{item.urduTranslation}</td>
                                                                            <td style={{ direction: 'ltr' }}>{item.englishTranslation}</td>
                                                                            <td className="align-center">
                                                                                <div className="btn-group">
                                                                                    
                                                                                    <Popup
                                                                                        trigger={
                                                                                         
                                                                                                                                                                                           
                                                                                              
                                                                                            <span className="review-icon" onClick={() => { editWords(item.wordId, item) }} title={"Review"}>

                                                                                                </span> 
                                                                                          
                                                                                        }
                                                                                        modal
                                                                                        nested
                                                                                        onOpen={() => editWords(item.wordId, item)}
                                                                                        closeOnDocumentClick={false}
                                                                                    >
                                                                                        {close => (
                                                                                            <div className="modal">
                                                                                                <button className="close" onClick={() => {
                                                                                                    //console.log('modal closed ');
                                                                                                    //if (window.confirm(Message.msg_close) == true)
                                                                                                    {
                                                                                                        close();
                                                                                                        refreshWfwDialogData();
                                                                                                    }
                                                                                                }}>
                                                                                                    &times;
                                                                                                </button>
                                                                                                <div className="header_review"><b> Review Edits Submitted for Approval ({'Surah ' + surahayatNum.surahNum + ' - Ayat ' + surahayatNum.ayatNum + ' - Word No. ' + item.word_position})</b></div>
                                                                                                <div className="divDialogContentContainerReview">
                                                                                                    <div className="content paddingLftRgt10xImp">
                                                                                                        <div className="form-row frm-btm-border frm-input">
                                                                                                            <div className="col col-md-8">
                                                                                                                <div className="form-row frm-input">
                                                                                                                    <div className="col col-md-2">
                                                                                                                        <label><span style={{ fontWeight: 'bold' }}>Nahv Color Codes: </span></label>
                                                                                                                    </div>
                                                                                                                    <div className="col col-md-10">
                                                                                                                        <div style={{ paddingTop: "10px" }}>
                                                                                                                            <NahvLegend />
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col col-md-4">
                                                                                                                <div className="form-row" style={{ /*marginBottom: "10px"*/ }}>
                                                                                                                    <div className="col col-md-3" >
                                                                                                                        <label>
                                                                                                                            <span style={{ fontWeight: 'bold' }}>Word: </span>
                                                                                                                        </label>

                                                                                                                    </div>
                                                                                                                    <div className="col col-md-9" style={{ textAlign: "right"}}>
                                                                                                                        <div style={{ paddingRight: "10px" }}>
                                                                                                                            {showComparison && word.sub_words != wordBeforeEdit.sub_words ? <><span className="inlineLargeAlMushafFont difference-text-highlight"><EqaContextMenu text={wordBeforeEdit.araab} id={idx} sub_words={wordBeforeEdit.sub_words} onSubWordsModelUpdate={onSubWordsModelUpdate} mode={"review"} /></span></> : <></>}
                                                                                                                            <span className="inlineLargeAlMushafFont"><EqaContextMenu text={word.araab} id={idx} sub_words={word.sub_words} onSubWordsModelUpdate={onSubWordsModelUpdate} mode={"review" }/></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="form-row ddl-row frm-input arabic-container">
                                                                                                            <div className="col text-align-right">
                                                                                                                <label dir="rtl" htmlFor="ddlMaaloomMajhool" style={{ fontFamily: "Alvi", textAlign: "right", fontSize: 16, display: "block", paddingRight: "15px" }}>معلوم/مجهول:</label>
                                                                                                                
                                                                                                                <select disabled id="ddlMaaloomMajhool" placeholder="معلوم۔" className="sarf-properties-dropdown" value={selectedDDLValues.pos3_value} onChange={handlePos3DDLChange}>
                                                                                                                    <option value="0"> --Select -- </option>
                                                                                                                    <option className="select-option" value="1">معلوم</option>
                                                                                                                    <option className="select-option" value="2">مجهول</option>
                                                                                                                </select>
                                                                                                                {showComparison && word.pos3_value != wordBeforeEdit.pos3_value ? <><br /><span className="difference-text">{wordBeforeEdit.pos3_value == "" || wordBeforeEdit.pos3_value == "0" || document.getElementById("ddlMaaloomMajhool") == null || document.getElementById("ddlMaaloomMajhool").options == null || document.getElementById("ddlMaaloomMajhool").options[wordBeforeEdit.pos3_value] == null ? '-' : document.getElementById("ddlMaaloomMajhool").options[wordBeforeEdit.pos3_value].text}</span></> : <></>}
                                                                                                            </div>
                                                                                                            <div className="col text-align-right">
                                                                                                                <label dir="rtl" for="ddlWho" style={{ fontFamily: "Alvi", textAlign: "right", fontSize: 16, display: "block", paddingRight: "15px" }}>صیغہ:</label>
                                                                                                                
                                                                                                                <select disabled id="ddlWho" placeholder="حاضر۔" className="sarf-properties-dropdown" value={selectedDDLValues.ishaara_picture_value} onChange={handleIsharaDDLChange}>
                                                                                                                    <option value="0"> --Select -- </option>
                                                                                                                    <option className="select-option" value="1">متكلم</option>
                                                                                                                    <option className="select-option" value="2">حاضر</option>
                                                                                                                    <option className="select-option" value="3">غائب</option>
                                                                                                                </select>
                                                                                                                {showComparison && word.ishaara_picture_value != wordBeforeEdit.ishaara_picture_value ? <><br /><span className="difference-text">{wordBeforeEdit.ishaara_picture_value == "" || wordBeforeEdit.ishaara_picture_value == "0" || document.getElementById("ddlWho") == null || document.getElementById("ddlWho").options == null || document.getElementById("ddlWho").options[wordBeforeEdit.ishaara_picture_value] == null ? '-' : document.getElementById("ddlWho").options[wordBeforeEdit.ishaara_picture_value].text}</span></> : <></>}
                                                                                                            </div>
                                                                                                            <div className="col text-align-right">
                                                                                                                <label dir="rtl" for="ddlGender" style={{ fontFamily: "Alvi", textAlign: "right", fontSize: 16, display: "block", paddingRight: "15px" }}>جنس:</label>
                                                                                                                
                                                                                                                <select disabled id="ddlGender" placeholder="جنس۔" class="sarf-properties-dropdown" value={selectedDDLValues.gender_value} onChange={handleGenderDDLChange}>
                                                                                                                    <option value="0">--Select --</option>
                                                                                                                    <option className="select-option" value="1">مذکر</option>
                                                                                                                    <option className="select-option" value="2">مؤنث</option>
                                                                                                                </select>
                                                                                                                {showComparison && word.gender_value != wordBeforeEdit.gender_value ? <><br /><span className="difference-text">{wordBeforeEdit == null || wordBeforeEdit.gender_value == null || wordBeforeEdit.gender_value == "" || wordBeforeEdit.gender_value == "0" || document.getElementById("ddlGender") == null || document.getElementById("ddlGender").options == null || document.getElementById("ddlGender").options[wordBeforeEdit.gender_value] == null ? '-' : document.getElementById("ddlGender").options[wordBeforeEdit.gender_value].text}</span></> : <></>}
                                                                                                            </div>
                                                                                                            <div className="col text-align-right">
                                                                                                                <label dir="rtl" for="ddlNumber" style={{ fontFamily: "Alvi", textAlign: "right", fontSize: 16, display: "block", paddingRight: "15px" }}>عدد:</label>
                                                                                                                
                                                                                                                <select disabled id="ddlNumber" placeholder="عدد۔" className="sarf-properties-dropdown" value={selectedDDLValues.number_value} onChange={handleNumberDDLChange}>
                                                                                                                    <option value="0"> --Select -- </option>
                                                                                                                    <option className="select-option" value="1">واحد</option>
                                                                                                                    <option className="select-option" value="2">جمع</option>
                                                                                                                    <option className="select-option" value="3">تثنیہ</option>
                                                                                                                </select>
                                                                                                                {showComparison && word.number_value != wordBeforeEdit.number_value ? <><br /><span className="difference-text">{wordBeforeEdit.number_value == "" || wordBeforeEdit.number_value == "0" || document.getElementById("ddlNumber") == null || document.getElementById("ddlNumber").options == null || document.getElementById("ddlNumber").options[wordBeforeEdit.number_value] == null ? '-' : document.getElementById("ddlNumber").options[wordBeforeEdit.number_value].text}</span></> : <></>}
                                                                                                            </div>
                                                                                                            <div className="col text-align-right">
                                                                                                                <label dir="rtl" for="ddlState" style={{ fontFamily: "Alvi", textAlign: "right", fontSize: 16, display: "block", paddingRight: "15px" }}>اعراب:</label>
                                                                                                                
                                                                                                                <select disabled id="ddlState" placeholder="مرفوع۔" className="sarf-properties-dropdown" value={selectedDDLValues.word_state_value} onChange={handleWordStateDDLChange}>
                                                                                                                    <option value="0"> --Select -- </option>
                                                                                                                    <option className="select-option" value="1">مرفوع</option>
                                                                                                                    <option className="select-option" value="2">منصوب</option>
                                                                                                                    <option className="select-option" value="3">مجرور</option>
                                                                                                                    <option className="select-option" value="4">مجزوم</option>
                                                                                                                </select>
                                                                                                                {showComparison && word.word_state_value != wordBeforeEdit.word_state_value ? <><br /><span className="difference-text">{wordBeforeEdit.word_state_value == "" || wordBeforeEdit.word_state_value == "0" || document.getElementById("ddlState") == null || document.getElementById("ddlState").options == null || document.getElementById("ddlState").options[wordBeforeEdit.word_state_value] == null ? '-' : document.getElementById("ddlState").options[wordBeforeEdit.word_state_value].text}</span></> : <></>}
                                                                                                            </div>

                                                                                                            <div className="col text-align-right">
                                                                                                                <label dir="rtl" for="ddlTense" style={{ fontFamily: "Alvi", textAlign: "right", fontSize: 16, display: "block", paddingRight: "15px" }}>فعل کی قسم:</label>
                                                                                                                
                                                                                                                <select disabled id="ddlTense" name="ddlTense" className='sarf-properties-dropdown' value={selectedDDLValues.tense} onChange={handleTenseDDLChange}>
                                                                                                                    <option value="0"> -- Select -- </option>
                                                                                                                    {sarfFormData.lstTense && sarfFormData.lstTense.map((h, i) =>
                                                                                                                        (<option className="select-option" key={i} value={h.value}>{h.label}</option>))}
                                                                                                                </select>
                                                                                                                {showComparison && word.tense != wordBeforeEdit.tense ? <><br /><span className="difference-text">{wordBeforeEdit.tense == "" || wordBeforeEdit.tense == "0" || document.getElementById("ddlTense") == null || document.getElementById("ddlTense").options == null || document.getElementById("ddlTense").options[wordBeforeEdit.tense] == null ? '-' : document.getElementById("ddlTense").options[wordBeforeEdit.tense].text}</span></> : <></>}
                                                                                                            </div>
                                                                                                            <div className="col text-align-right">
                                                                                                                <label dir="rtl" for="ddlNounType" style={{ fontFamily: "Alvi", textAlign: "right", fontSize: 16, display: "block", paddingRight: "15px" }}>اسم کی قسم:</label>
                                                                                                                
                                                                                                                <select disabled id="ddlNounType" name="ddlNounType" className='sarf-properties-dropdown' value={selectedDDLValues.noun_type_id} onChange={handleNounTypeDDLChange}>
                                                                                                                    <option value="0"> -- Select -- </option>
                                                                                                                    {sarfFormData.lstNounType && sarfFormData.lstNounType.map((h, i) =>
                                                                                                                        (<option className="select-option" key={i} value={h.value}>{h.label}</option>))}
                                                                                                                </select>
                                                                                                                {showComparison && word.noun_type_id != wordBeforeEdit.noun_type_id ? <><br /><span className="difference-text">{wordBeforeEdit.noun_type_id == null || wordBeforeEdit.noun_type_id == "" || wordBeforeEdit.noun_type_id == "0" || document.getElementById("ddlNounType") == null || document.getElementById("ddlNounType").options == null || document.getElementById("ddlNounType").options[wordBeforeEdit.noun_type_id] == null ? '-' : document.getElementById("ddlNounType").options[wordBeforeEdit.noun_type_id].text}</span></> : <></>}
                                                                                                            </div>

                                                                                                            <div className="col text-align-right">
                                                                                                                <label dir="rtl" for="ddlBaab" style={{ fontFamily: "Alvi", textAlign: "right", fontSize: 16, display: "block", paddingRight: "15px" }}>ریفرنس باب:</label>
                                                                                                                
                                                                                                                <select disabled id="ddlBaab" name="ddlBaab" className='sarf-properties-dropdown' placeholder="باب۔" value={selectedDDLValues.baab_code} onChange={handleBaabCodeDDLChange}>
                                                                                                                    <option value="0"> -- Select -- </option>
                                                                                                                    {sarfFormData.lstBaab && sarfFormData.lstBaab.filter(filterBaabs).map((h, i) =>
                                                                                                                        (<option className="select-option" key={i} value={h.value}>{h.label}</option>))}
                                                                                                                </select>
                                                                                                                {showComparison && word.baab_code != wordBeforeEdit.baab_code ? <><br /><span className="difference-text">{wordBeforeEdit.baab_name == null || wordBeforeEdit.baab_name == "" ? '-' : wordBeforeEdit.baab_name}</span></> : <></>}
                                                                                                            </div>
                                                                                                            <div className="col text-align-right">
                                                                                                                <label dir="rtl" for="ddlTraditionalBaab" style={{ fontFamily: "Alvi", textAlign: "right", fontSize: 16, display: "block", paddingRight: "15px" }}>باب:</label>
                                                                                                                
                                                                                                                <select disabled id="ddlTraditionalBaab" name="ddlTraditionalBaab" className='sarf-properties-dropdown' placeholder="باب۔" value={selectedDDLValues.traditional_baab_id} onChange={handleTraditionalBaabDDLChange}>
                                                                                                                    <option value="0"> -- Select -- </option>
                                                                                                                    {sarfFormData.lstTraditionalBaab && sarfFormData.lstTraditionalBaab.map((h, i) =>
                                                                                                                        (<option className="select-option" key={i} value={h.value}>{h.label}</option>))}
                                                                                                                </select>
                                                                                                                {showComparison && word.traditional_baab_id != wordBeforeEdit.traditional_baab_id ? <><br /><span className="difference-text">{wordBeforeEdit.traditional_baab_id == "" || wordBeforeEdit.traditional_baab_id == "0" || document.getElementById("ddlTraditionalBaab") == null || document.getElementById("ddlTraditionalBaab").options == null || document.getElementById("ddlTraditionalBaab").options[wordBeforeEdit.traditional_baab_id] == null ? '-' : document.getElementById("ddlTraditionalBaab").options[wordBeforeEdit.traditional_baab_id].text}</span></> : <></>}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="form-row frm-input frm-input-sarf urdu-container" style={{ marginBottom: "5px" }}>
                                                                                                            <div className="col col-md-3">
                                                                                                                <div>
                                                                                                                    <label for="urduTranslation">
                                                                                                                        <span style={{ fontWeight: 'bold' }}>Urdu Translation: </span>
                                                                                                                    </label>
                                                                                                                    
                                                                                                                </div>
                                                                                                                <div className="text-align-right">
                                                                                                                    <textarea 
                                                                                                                        readOnly  
                                                                                                                    style={{
                                                                                                                        resize: "none",
                                                                                                                        height: "60px",
                                                                                                                        fontSize: "20px",
                                                                                                                        fontFamily: "Alvi",
                                                                                                                        textAlign: "right"
                                                                                                                        }} className="form-control" rows="3" id="urduTranslation" name="urduTranslation" type="text" value={word.urduTranslation} onChange={(event) => { handleWordChange(event, word) }} />
                                                                                                                    {showComparison && word.urduTranslation != wordBeforeEdit.urduTranslation ? <><span className="difference-text">{wordBeforeEdit.urduTranslation == null || wordBeforeEdit.urduTranslation == "" ? '-' : wordBeforeEdit.urduTranslation}</span></> : <></>}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col col-md-3">
                                                                                                                <div>
                                                                                                                    <label for="englishTranslation">
                                                                                                                        <span style={{ fontWeight: 'bold' }}>English Translation: </span>
                                                                                                                    </label>
                                                                                                                    
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <textarea
                                                                                                                        readOnly style={{
                                                                                                                        resize: "none", height: "60px",
                                                                                                                        fontSize: "16px", textAlign: "left"
                                                                                                                        }} className="form-control" rows="3" id="englishTranslation" name="englishTranslation" type="text" value={word.englishTranslation} onChange={(event) => { handleWordChange(event, word) }} />
                                                                                                                    {showComparison && word.englishTranslation != wordBeforeEdit.englishTranslation ? <><span className="difference-text">{wordBeforeEdit.englishTranslation == null || wordBeforeEdit.englishTranslation == "" ? '-' : wordBeforeEdit.englishTranslation}</span></> : <></>}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col col-md-3">
                                                                                                                <div>
                                                                                                                    <label for="meaning_maazi_urdu">
                                                                                                                        <span style={{ fontWeight: 'bold' }}>Meaning Maazi Urdu: </span>
                                                                                                                    </label>
                                                                                                                    
                                                                                                                </div>
                                                                                                                <div className="text-align-right">
                                                                                                                    <textarea
                                                                                                                        readOnly style={{
                                                                                                                        resize: "none", fontFamily: "Alvi", height: "60px",
                                                                                                                        fontSize: "20px", textAlign: "right"
                                                                                                                        }} className="form-control" rows="3" id="meaning_maazi_urdu" name="meaning_maazi_urdu" type="text" value={word.meaning_maazi_urdu} onChange={(event) => { handleWordChange(event, word) }} />
                                                                                                                    {showComparison && word.meaning_maazi_urdu != wordBeforeEdit.meaning_maazi_urdu? <><span className="difference-text">{wordBeforeEdit.meaning_maazi_urdu == null || wordBeforeEdit.meaning_maazi_urdu == "" ? '-' : wordBeforeEdit.meaning_maazi_urdu}</span></> : <></>}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col col-md-3">
                                                                                                                <div>
                                                                                                                    <label for="meaning_maazi_english">
                                                                                                                        <span style={{ fontWeight: 'bold' }}>Meaning Maazi English: </span>
                                                                                                                    </label>
                                                                                                                    
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    <textarea
                                                                                                                        readOnly style={{
                                                                                                                        resize: "none",
                                                                                                                        height: "60px",
                                                                                                                        fontSize: "16px",
                                                                                                                        }} className="form-control" rows="3" id="meaning_maazi_english" name="meaning_maazi_english" type="text" value={word.meaning_maazi_english} onChange={(event) => { handleWordChange(event, word) }} />
                                                                                                                    {showComparison && word.meaning_maazi_english != wordBeforeEdit.meaning_maazi_english ? <><span className="difference-text">{wordBeforeEdit.meaning_maazi_english == null || wordBeforeEdit.meaning_maazi_english == "" ? '-' : wordBeforeEdit.meaning_maazi_english}</span></> : <></>}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            
                                                                                                        </div>
                                                                                                        <div className="form-row frm-input frm-input-sarf" style={{ marginBottom: "5px" }}>
                                                                                                            <div className="col col-md-3">
                                                                                                                <div id="RTLabel">
                                                                                                                    <label for="root_text">
                                                                                                                        <span style={{ fontWeight: 'bold' }}>Root Text: </span>
                                                                                                                    </label>
                                                                                                                    
                                                                                                                </div>
                                                                                                                <div id="RTInput" className="text-align-right">
                                                                                                                    <input readOnly className="form-control align-right direction-rtl" id="root_text" style={Majidi_v5FontSmall} name="root_text" type="text" value={word.root_text} onChange={(event) => { handleWordChange(event, word) }} />
                                                                                                                    {showComparison && word.root_text != wordBeforeEdit.root_text ? <><span className="difference-text">{wordBeforeEdit.root_text == null || wordBeforeEdit.root_text == "" ? '-' : wordBeforeEdit.root_text}</span></> : <></>}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col col-md-3">
                                                                                                                <div>
                                                                                                                    <label for="extrafield1">
                                                                                                                        <span style={{ fontWeight: 'bold' }}>Extra Field 1: </span>
                                                                                                                    </label>
                                                                                                                    
                                                                                                                </div>
                                                                                                                <div className="text-align-right">
                                                                                                                    <input readOnly style={{
                                                                                                                        resize: "none", fontFamily: "Alvi",
                                                                                                                        fontSize: "20px", textAlign: "right"
                                                                                                                    }} className="form-control" id="extrafield1" name="extrafield1" type="text" value={extraFields.extra_field1} onChange={(event) => { handleExtraFieldsChange(event, "1") }} />
                                                                                                                    {showComparison ? <><span className="difference-text">{extraFieldsBeforeEdit.extra_field1}</span></> : <></>}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col col-md-3">
                                                                                                                <div>
                                                                                                                    <label for="extrafield2">
                                                                                                                        <span style={{ fontWeight: 'bold' }}>Extra Field 2: </span>
                                                                                                                    </label>
                                                                                                                    
                                                                                                                </div>
                                                                                                                <div className="text-align-right">
                                                                                                                    <input readOnly style={{
                                                                                                                        resize: "none", fontFamily: "Alvi",
                                                                                                                        fontSize: "20px", textAlign: "right"
                                                                                                                    }} className="form-control" id="extrafield2" name="extrafield2" type="text" value={extraFields.extra_field2} onChange={(event) => { handleExtraFieldsChange(event, "2") }} />
                                                                                                                    {showComparison ? <><span className="difference-text">{extraFieldsBeforeEdit.extra_field2}</span></> : <></>}
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div className="col col-md-3">
                                                                                                                <div>
                                                                                                                    <div id="JMLabel" style={{ display: 'none' }}>
                                                                                                                        <label for="jama_mukassar_wahid">
                                                                                                                            <span style={{ fontWeight: 'bold' }}>Wahid for Jama Mukasar: </span>
                                                                                                                        </label>
                                                                                                                        
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div className="text-align-right">
                                                                                                                    <div id="JMInput" style={{ display: 'none' }}>
                                                                                                                        <input readOnly className="form-control align-right direction-rtl" id="jama_mukassar_wahid" style={Majidi_v5FontSmall} name="jama_mukassar_wahid" type="text" value={word.jama_mukassar_wahid} onChange={(event) => { handleWordChange(event, word) }} />
                                                                                                                        {showComparison && word.jama_mukassar_wahid != wordBeforeEdit.jama_mukassar_wahid ? <><span className="difference-text">{wordBeforeEdit.jama_mukassar_wahid == null || wordBeforeEdit.jama_mukassar_wahid == "" ? '-' : wordBeforeEdit.jama_mukassar_wahid}</span></> : <></>}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                    <div className="content grammar-table">
                                                                                                        <ReactTable isReadonly={true} columns={columns} data={wordGrammerData} setWordGrammerData={setWordGrammerData}
                                                                                                            updatedWordsData={updatedWordsData} 
                                                                                                            //setupdatedData={setupdatedData} 
                                                                                                        />
                                                                                                        <ReactTable isReadonly={true} columns={columns} data={wordGrammerDiff} setWordGrammerData={setWordGrammerData}
                                                                                                            updatedWordsData={updatedWordsData}
                                                                                                            //setupdatedData={setupdatedData} 
                                                                                                            color={"red"}
                                                                                                            hideHeader={true }
                                                                                                        />
                                                                                                    </div>
                                                                                                </div>
                                                                                                {/*<div className="actions" >*/}
                                                                                                {/*    <div className="form-row frm-input" style={{ marginBottom: "5px" }}>*/}

                                                                                                {/*        <div className="col">*/}
                                                                                                {/*            <Button variant="success" onClick={() => { updateRecord(false) ?? close() }}>Save</Button>*/}

                                                                                                {/*            <Button className="margin-left-10" variant="success" onClick={() => { if (window.confirm(Message.msg_update_all) == true && wordCount > 0) { updateRecord(true) ?? close() } }}>Save For All {wordCount} Words with same text</Button>*/}


                                                                                                {/*            <Button className="margin-left-10" variant="secondary" onClick={() => {*/}
                                                                                                {/*                //console.log('modal closed ');*/}
                                                                                                {/*                if (window.confirm(Message.msg_close) == true) {*/}
                                                                                                {/*                    close();*/}
                                                                                                {/*                }*/}
                                                                                                {/*            }}>Cancel</Button>*/}
                                                                                                {/*        </div>*/}
                                                                                                {/*    </div>*/}
                                                                                                {/*</div>*/}
                                                                                                {/*<div className="file-upload-content" >*/}
                                                                                                {/*    <div className="form-row frm-input">*/}
                                                                                                {/*        <div className="col col-md-6">*/}
                                                                                                {/*            <FileUpload wordId={item.wordId} fileType={"Synonyms"} labelValue="Upload Synonyms (مترادفات) file:" uplodedFileName={item.synonyms_filename} />*/}
                                                                                                {/*        </div>*/}
                                                                                                {/*        <div className="col col-md-6">*/}
                                                                                                {/*            <FileUpload wordId={item.wordId} fileType={"Stars"} labelValue="Upload Stars file:" uplodedFileName={item.stars_filename} />*/}
                                                                                                {/*        </div>*/}
                                                                                                {/*    </div>*/}
                                                                                                {/*</div>*/}
                                                                                                <div className="review-container">
                                                                                                    <div className="form-group">
                                                                                                        <label htmlFor="reviewComments" style={{ fontWeight: 'bold' }}>Review Comments:</label>
                                                                                                        <textarea
                                                                                                            id="reviewComments"
                                                                                                            className="form-control"
                                                                                                            rows="2"
                                                                                                            placeholder="Enter your review comments here..."
                                                                                                            value={reviewComments}
                                                                                                            onChange={(event) => setReviewComments(event.target.value)}
                                                                                                        />
                                                                                                    </div>
                                                                                                    {!word.is_save_all ? null : <div className="mt-3 p-2 " style={{ backgroundColor: '#fff3cd', borderRadius: '5px', fontSize: '1.2rem', color: 'red' }}>
                                                                                                        Note: This update impacts {word.words_edit_count} words containing same text.
                                                                                                    </div>}
                                                                                                    <div className="mt-3 text-right">
                                                                                                        
                                                                                                        <div class="row">
                                                                                                            <div class="col text-align-left"><label htmlFor="editedBy" >Submitted by {word.edited_by} ({word.edited_on})</label></div>
                                                                                                            <div class="col">{word.is_save_all ?
                                                                                                                <button className="btn btn-success mr-2" onClick={() => { updateRecord(true) ?? close() }}>
                                                                                                                    Approve For All {word.words_edit_count} Words with Same Text
                                                                                                                </button> :
                                                                                                                <button className="btn btn-success mr-2" onClick={() => { updateRecord(true) ?? close() }}>
                                                                                                                    Approve
                                                                                                                </button>
                                                                                                            }
                                                                                                                <button className="btn btn-danger" onClick={() => { updateRecord(false) ?? close() }}>
                                                                                                                    Reject
                                                                                                                </button>
                                                                                                                <button className="btn btn-secondary ml-2" onClick={() => {
                                                                                                                    //if (window.confirm(Message.msg_close) == true)
                                                                                                                    {
                                                                                                                        close();
                                                                                                                        refreshWfwDialogData();
                                                                                                                    }
                                                                                                                }}>
                                                                                                                    Cancel
                                                                                                                </button>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        )}
                                                                                    </Popup>

                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    })
                                                                }
                                                            </tbody>
                                                        </Table>

                                                    </Card>
                                                </Col>
                                            </Row></div> : null}
                                </CardBody>
                            </TabPanel>
                          
                        </Tabs>

                        : null}
                </div>
            </BlockUi>
        </div>

    )
}
export default ReviewList