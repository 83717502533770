import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
class Logout extends Component {
    constructor(props) {
        super(props);
    }
    render() {
        sessionStorage.clear();
        localStorage.clear();
        this.props.history.push("/login");
        return <Redirect to='/login' />
    }
}
export default Logout;  