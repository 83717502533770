import { useState } from 'react';

export default function AuthContext() {
    const getToken = () => {
        const tokenString = localStorage.getItem('token');
        const userToken = tokenString != 'undefined' ? JSON.parse(tokenString) : null;
        return userToken != null ? userToken : '';
    };

    const [token, setToken] = useState(getToken());

    const saveToken = userToken => {
        localStorage.setItem('token', JSON.stringify(userToken));
        setToken(userToken != null ? userToken : '');
    };

    return {
        setToken: saveToken,
        token
    }
}