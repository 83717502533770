import React from 'react';

const EqaHelper = {
    getCategoryColorCode: function (category) {
        let categoryColorCode = "";
        switch (category) {
            case "afaal":
                categoryColorCode = "#cc0000";
                break;
            case "asma":
                categoryColorCode = "#0099CC";
                break;
            case "faael":
                categoryColorCode = "#33CC66";
                break;
            case "mafool":
                categoryColorCode = "#FF00FF";
                break;
            case "haal":
                categoryColorCode = "#6e1835";
                break;
            case "sift":
                categoryColorCode = "#0000FF";
                break;
            case "tameeaz":
                categoryColorCode = "#999933";
                break;
            case "zaraf":
                categoryColorCode = "#663399";
                break;
            case "takeed":
                categoryColorCode = "#996633";
                break;
            case "zameer_shaan":
                categoryColorCode = "#9ab416";
                break;
            case "ism_fael":
                categoryColorCode = "#0b503d";
                break;
            default:
                categoryColorCode = "#000";
                break;
        }
        return categoryColorCode;
    },
    createMarkup: function (paramWord, paramSubWords) {
        if (paramSubWords == null) {
            return paramWord;
        }
        let arrSubWords = JSON.parse("["+paramSubWords+"]");
        let _html = "";
        let w = null;
        for (let j = 0; j < arrSubWords.length; j++) {
            w = arrSubWords[j];
            let colorCode = this.getCategoryColorCode(w.category);
            let str = `<span cid=${j} style="color:${colorCode}">${w.word}</span>`;
            _html += str;
        }
        return _html;
    },
    embedSubWordOffsets: function(paramSubWords)
    {
        let indx=0;
        for(let i=0;i<paramSubWords.length;i++)
        {
            let sWord=paramSubWords[i];
            sWord.startOffset=indx;
            sWord.endOffset=indx+sWord.word.length;
            indx=sWord.endOffset;
        }
        return paramSubWords;
    },
    createSubWord : function(category,paramWordText,paramStartOffset,paramEndOffset,id)
    {
        if(paramEndOffset-paramStartOffset<=0)
            return null;
        let subWordText=paramWordText.substring(paramStartOffset,paramEndOffset);
        let jsonString = `{"category":"${category}", "word": "${subWordText}" , "id":"${id}", "startOffset":${paramStartOffset},"endOffset":${paramEndOffset}}`;
       return JSON.parse(jsonString);
    },
    splitWord : function(category,paramWordText, preSubWordStartOffset,preSubWordEndOffset,postSubWordStartOffset,postSubWordEndOffset)
    {
        let splitArr=[];

        let preSubWord = this.createSubWord(category,paramWordText,preSubWordStartOffset,preSubWordEndOffset,0);
        if(preSubWord)
        {
            splitArr.push(preSubWord);
          
        }
       
        let postSubWord = this.createSubWord(category,paramWordText,postSubWordStartOffset,postSubWordEndOffset,1);
        if(postSubWord)
        {
            splitArr.push(postSubWord)
        }    
        return splitArr;       

    },
    getSubWords: function(category,paramWordText, paramSubWords, paramHighlightedText, paramStartOffset, paramEndOffset)
    {
        //debugger;
        //'الْعٰلَمِيْنَ'
        //'{"word": "الْ" , "id":"1"},{  "category": "asma", "word":"عٰلَمِيْنَ" , "id":"2"}'
        //'لَمِيْنَ'
        //5
        //13
      //  alert(category);
        if (paramEndOffset >= paramWordText.length) {
            paramEndOffset = paramWordText.length - 1;
        }
        if (paramEndOffset > 25) {
            console.log("EQAHelper.js > getSubWords > params:", category, paramWordText, paramSubWords, paramHighlightedText, paramStartOffset, paramEndOffset);
            alert("EQAHelper.js > getSubWords > params > category: " + category + ", paramWordText: " + paramWordText + ", paramSubWords: " + paramSubWords + ", paramHighlightedText: " + paramHighlightedText + ", paramStartOffset: " + paramStartOffset + ", paramEndOffset: " + paramEndOffset);
        }
        if (category == null || category == '') {
            category = "default";
        }
        if(paramHighlightedText==null || paramHighlightedText=='')
        {
            paramHighlightedText = paramWordText;
            paramStartOffset = 0;
            paramEndOffset = paramWordText.length - 1;
        }
        let subWords=null;
        if(paramSubWords!=null && paramSubWords!=null)
        {
            subWords=JSON.parse('['+paramSubWords+']');
            subWords=this.embedSubWordOffsets(subWords);    
        }       
        //debugger;
        //let newSubWord=JSON.parse(`{"word": "${paramHighlightedText}" , "id":"-1", "startOffset":"${paramStartOffset}","endOffset":"${paramEndOffset}"}`);
        let  newSubWord = this.createSubWord(category, paramWordText, paramStartOffset, paramEndOffset+1, 0);
              
        let arr=[];
        arr.push(newSubWord); 
        //debugger;
        if (subWords == null)
        {
            // let preSubWord = this.createSubWord('default',paramWordText,0,paramStartOffset,0);
            // if(preSubWord)
            // {
            //     arr.push(preSubWord);
            //     newSubWord.id=1;
            // }
            // arr.push(newSubWord);
            // let postSubWord = this.createSubWord('default',paramWordText,paramEndOffset,paramWordText.length,2);
            // if(postSubWord)
            // {
            //     arr.push(postSubWord)
            // }   
            if (paramEndOffset - paramStartOffset != paramWordText.length) {
                let splitArr = this.splitWord('default', paramWordText, 0, paramStartOffset, paramEndOffset+1, paramWordText.length);
                arr.push(...splitArr);
            }
        }
        else
        {                        
            let w=null;           
           // let postSubWords=[];
            //let newSubWordAdded=false;
            for(let i=0;i<subWords.length;i++)
            {
                w=subWords[i];
                if(w.endOffset<=newSubWord.startOffset)
                {
                    arr.push(w);
                }
                else if  (w.startOffset<newSubWord.startOffset && w.endOffset>=newSubWord.startOffset && w.endOffset<=newSubWord.endOffset)
                {
                    w.endOffset=newSubWord.startOffset	//shrink in start
                    w.word=paramWordText.substring(w.startOffset,w.endOffset);
                    arr.push(w);
                }
                else if  (w.startOffset<newSubWord.startOffset &&  w.endOffset>=newSubWord.endOffset)//split case
                {                    
                    // let splitArr=[];
                    // let preSubWord = this.createSubWord(w.category,paramWordText,w.startOffset,newSubWord.startOffset,arr.length);
                    // if(preSubWord)
                    // {
                    //     splitArr.push(preSubWord);
                    // }
                    // let postSubWord = this.createSubWord(w.category,paramWordText,newSubWord.endOffset,w.endOffset,arr.length+2);
                    // if(postSubWord)
                    // {
                    //     splitArr.push(postSubWord)
                    // }
                    // arr.push(...splitArr);
                    if (paramEndOffset - paramStartOffset != paramWordText.length) {
                        let splitArr = this.splitWord(w.category, paramWordText, w.startOffset, newSubWord.startOffset, newSubWord.endOffset, w.endOffset);
                        arr.push(...splitArr);
                    }
                }
            
                else if (w.startOffset>=newSubWord.startOffset && w.startOffset<newSubWord.endOffset && w.endOffset>newSubWord.endOffset)
                {
                    w.startOffset =newSubWord.endOffset		//shrink at end	
                    w.word=paramWordText.substring(w.startOffset,w.endOffset);
                    arr.push(w);
                }
                 else if(w.startOffset>=newSubWord.endOffset)
                {
                    arr.push(w);//post words
                }

            }                       
                           
        }
        arr.sort((a, b) => a.startOffset - b.startOffset);
        for(let i=0;i<arr.length;i++)
        {
            arr[i].id = i;
            delete arr[i].startOffset;
            delete arr[i].endOffset;
        }
        return arr;
    },

    getSpaceSeparatedChars: function(paramMultilingualString) {
        if(paramMultilingualString==null)
        {
            return "";
        }
        paramMultilingualString = paramMultilingualString.toString();
        let result = paramMultilingualString.split('').join(' ')
        result = result + " ";
        return result;
    },
    getSpaceRemovedChars: function (paramMultilingualString) {
        let result = paramMultilingualString.replaceAll(' ','');
        return result;
    },
    parseJwt: function (token) {
        console.log("token:", token);
        if (token == null) {
            return "";
        }
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    },
    isValidJson: function (json) {
        try {
            JSON.parse(json);
            return true;
        } catch (error) {
            return false;
        }
    },
    ExtractWordFromJson: function (json_data) {
        let word = '';
        let json = '[' + json_data + ']'; // Wrap the input as JSON array

        // Check if the input is valid JSON
        if (this.isValidJson(json)) {
            // Extract each word part from the JSON array
            let jsonArray = JSON.parse(json);
            for (let i = 0; i < jsonArray.length; i++) {
                let word_part = jsonArray[i].word;
                if (word_part) {
                    word += word_part + '';
                }
            }
        }
        return word.trim(); // Remove trailing space
    },

};

export default EqaHelper;