import React from 'react'
import { Card, CardBody, CardHeader, Col, Pagination, PaginationItem, PaginationLink, Row, Table, Input, InputGroup } from 'reactstrap';
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Button, Spinner } from 'react-bootstrap';
import { render } from "react-dom";
import ReactTable from '../Table/ReactTable.js';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { API_URL } from '../constant/URL';
//import "react-table/react-table.css";
import EqaContextMenu from '../ContextMenu/EqaContextMenu';
import NahvLegend from '../ContextMenu/NahvLegend';
import EqaHelper from '../Helper/EqaHelper.js';
import Message from '../constant/Message';
import zxcvbn from 'zxcvbn';
import PasswordStrengthMeter from './PasswordStrengthMeter';
import 'font-awesome/css/font-awesome.min.css';

function User(props) {
    const [blocking, setBlocking] = useState([false]);
    const [passwordShown, setPasswordShown] = useState(false);
    const [password, setPassword] = useState('');
    const [editPasswordShown, setEditPasswordShown] = useState(false);
    const [editPassword, setEditPassword] = useState('');
    const [user, setUser] = React.useState([]);
    const [users, setUsers] = React.useState([]);
    const [validated, setValidated] = useState(false);
    const [editValidated, setEditValidated] = useState(false);
    const [addUser, setAddUser] = React.useState({ UserName: "", Email: "", Password: "", ConfirmPassword: "" });
    const [showIcons, setShowIcons] = useState(true);
    const editModal = useRef(null);
    const addModal = useRef(null);
    useEffect(() => {
        const GetUsers = async () => {
            axios(`${API_URL}` + 'User/GetUsers/')
                .then((result) => {
                    setUsers(result.data);
                });

        };
        GetUsers();
    }, []);

    const deleteUser = (_user) => {
        try {
            axios.post(`${API_URL}` + 'User/DeleteUser/', _user)
                .then((result) => {
                    if (result.data) {
                        toast.success('User is deleted successfully.');
                    }
                    else {
                        toast.error('Failed to delete user.');
                    }
                    axios(`${API_URL}` + 'User/GetUsers/')
                        .then((result) => {
                            setUsers(result.data);                            
                            setTimeout(function () { setBlocking(false); }, 1500);
                        });
                });
        } catch (e) {
            toast.error('Error: ' + e);
        }
    };

    const updateUser = (event) => {
        try {
            event.preventDefault();
            event.stopPropagation();
            var testResult = "";
            if (user.password)
                testResult = zxcvbn(user.password);
            const _form = event.currentTarget;
            if (_form.checkValidity() === false) {
                setEditValidated(true);
            }
            else if (!validateEmail(user.email)) {
                setEditValidated(true);
                alert(Message.msg_invalid_email);
            }
            else if (user.password !== user.confirm_Password) {
                setEditValidated(true);
                alert(Message.msg_invalid_confirm_password);
            }
            else if (testResult.score < 3) {
                setEditValidated(true);
                alert(Message.msg_invalid_password);
            }
            else if (_form.checkValidity() === true && validateEmail(user.email)) {
                setEditValidated(false);
                setBlocking(true);
                axios.post(`${API_URL}` + 'User/UpdateUser/', user)
                    .then((result) => {
                        if (result.data) {
                            toast.success('User is updated successfully.');
                            user.userName = "";
                            user.email = "";
                            user.password = "";
                            user.confrim_password = "";
                            setEditPassword('');
                            hideEditModal();
                        }
                        else {
                            hideEditModal();
                            toast.error('Failed to update user.');
                        }
                        axios(`${API_URL}` + 'User/GetUsers/')
                            .then((result) => {
                                setUsers(result.data);
                                setTimeout(function () { setBlocking(false); }, 1500);
                        });
                    });
            }
        } catch (e) {
            toast.error('Error: ' + e);
        }
    };

    const hideEditModal = () => {
        editModal.current.style['display'] = 'none';
        editModal.current.parentElement.style['display'] = 'none';
        editModal.current.parentElement.parentElement.style['display'] = 'none';
    };

    const InsertUser = (event) => {
        try {
            event.preventDefault();
            event.stopPropagation();
            var testResult = "";
            if (addUser.Password)
                testResult = zxcvbn(addUser.Password);
            const _form = event.currentTarget;
            if (_form.checkValidity() === false) {
                setValidated(true);
            }
            else if (!validateEmail(addUser.Email)) {
                setValidated(true);
                alert(Message.msg_invalid_email);
            }
            else if (addUser.Password !== addUser.ConfirmPassword) {
                setValidated(true);
                alert(Message.msg_invalid_confirm_password);
            }
            else if (testResult.score < 3) {
                setValidated(true);
                alert(Message.msg_invalid_password);
            }
            else if (_form.checkValidity() === true && validateEmail(addUser.Email) && addUser.Password == addUser.ConfirmPassword && testResult.score > 2) {
                setValidated(false);
                setBlocking(true);
                axios.post(`${API_URL}` + 'User/AddUser/', addUser)
                    .then((result) => {
                        if (result.data) {
                            toast.success('User is added successfully.');
                            addUser.UserName = "";
                            addUser.Email = "";
                            addUser.Password = "";
                            addUser.ConfirmPassword = "";
                            setPassword('');
                            hideAddModal();
                        }
                        else {
                            hideAddModal();
                            toast.error('Failed to add user.');
                        }
                        axios(`${API_URL}` + 'User/GetUsers/')
                            .then((result) => {
                                setUsers(result.data);
                                setTimeout(function () { setBlocking(false); }, 1500);
                            });
                    });
            }
        } catch (e) {
            toast.error('Error: ' + e);
        }
    };

    const hideAddModal = () => {
        addModal.current.style['display'] = 'none';
        addModal.current.parentElement.style['display'] = 'none';
        addModal.current.parentElement.parentElement.style['display'] = 'none';
    };

    function validateEmail(email) {
        const regexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return regexp.test(email);
    }

    const addUserNameChange = (value) => {
        setAddUser({ ...addUser, UserName: value });
    };

    const addEmailChange = (value) => {
        setAddUser({ ...addUser, Email: value });
    };

    const addPasswordChange = (value) => {
        setPassword(value);
        setAddUser({ ...addUser, Password: value });
    };

    const addConfirmPasswordChange = (value) => {
        setAddUser({ ...addUser, ConfirmPassword: value });
    };

    const handleUserChange = (e, user) => {
        if (e.target.name === "userName") {
            e.persist();
            setUser({ ...user, [e.target.name]: e.target.value });
        }
        else if (e.target.name === "email") {
            e.persist();
            setUser({ ...user, [e.target.name]: e.target.value });
        }
        else if (e.target.name === "password") {
            e.persist();
            setEditPassword(e.target.value);
            setUser({ ...user, [e.target.name]: e.target.value });
        }
        else if (e.target.name === "confirm_Password") {
            e.persist();
            setUser({ ...user, [e.target.name]: e.target.value });
        }
    };

    const editSelectedUser = (_user) => {
        setUser(_user);
    }

    const addUserModal = () => {
        addUser.userName = "";
        addUser.email = "";
        addUser.password = "";
        addUser.confirmPassword = "";
    }

    const togglePassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setPasswordShown(!passwordShown);
    };

    const toggleEditPassword = () => {
        // When the handler is invoked
        // inverse the boolean state of passwordShown
        setEditPasswordShown(!editPasswordShown);
    };

    return (
        <div>
            <h2>User Management</h2>
            <p className="help-text">This section allows managing the users for the Easy Quranic Arabic application.</p>
            <Popup
                trigger={
                    <Button style={{ display: "block", marginLeft: "auto", marginRight: 0, marginBottom: 10 }} onClick={() => { addUserModal() }} className="btn-outline-info">Add User</Button>
                }
                modal
                nested
                closeOnDocumentClick={false}
            >
                {close => (
                    <div className="modal" ref={addModal} id="UserModal">
                        <button className="close" id="UserModalClose" onClick={() => {
                            //console.log('modal closed ');

                            if (window.confirm(Message.msg_close) == true) {
                                addUser.UserName = "";
                                addUser.Email = "";
                                addUser.Password = "";
                                addUser.ConfirmPassword = "";
                                setPassword('');
                                setValidated(false);
                                close();
                            }
                        }}>
                            &times;
                        </button>
                        <div className="header"> Add User </div>
                        <Form noValidate validated={validated} onSubmit={InsertUser}>
                            <div className="content" style={{ padding: 5 }}>

                                <Row className="mb-3">

                                    <Form.Group as={Col} md="6" controlId="Add_user_name">
                                        <Form.Label>User Name:</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="User Name"
                                            name="Add_user_name"
                                            value={addUser.UserName}
                                            onChange={(event) => { addUserNameChange(event.target.value) }}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="Add_email">
                                        <Form.Label>Email:</Form.Label>
                                        <Form.Control
                                            required
                                            type="text"
                                            placeholder="Email"
                                            name="Add_email"
                                            value={addUser.Email}
                                            onChange={(event) => { addEmailChange(event.target.value) }}
                                        />
                                    </Form.Group>
                                </Row>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md="6" controlId="Add_password">
                                        <Form.Label>Password:</Form.Label>
                                        <Form.Control
                                            className="shadow-none mb-1"
                                            required
                                            style={{width: '95%'}}
                                            type={passwordShown ? "text" : "password"}
                                            placeholder="Password"
                                            name="Add_password"
                                            value={addUser.Password}
                                            onChange={(event) => { addPasswordChange(event.target.value) }}
                                        />
                                        <i onClick={togglePassword} className={`fa ${passwordShown ? "fa-eye-slash" : "fa-eye"}`} style={{ cursor: 'pointer', zIndex: '200', position: 'absolute', right: '20px', top: '35px', fontSize:'large' }}></i>
                                        <PasswordStrengthMeter password={password} />
                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId="Add_confirm_password">
                                        <Form.Label>Confirm Password:</Form.Label>
                                        <Form.Control
                                            required
                                            type="password"
                                            placeholder="Confirm Password"
                                            name="Add_confirm_password"
                                            value={addUser.ConfirmPassword}
                                            onChange={(event) => { addConfirmPasswordChange(event.target.value) }}
                                        />
                                    </Form.Group>
                                </Row>
                            </div>

                            <div className="actions" style={{ padding: 5 }}>
                                {/*<Button variant="success" onClick={() => { addIshaaraat() ?? close() }}>Save</Button>*/}
                                <Button type="submit" value="Save">Save</Button>
                                <Button className="margin-left-10" variant="secondary" onClick={() => {

                                    if (window.confirm(Message.msg_close) == true) {
                                        addUser.UserName = "";
                                        addUser.Email = "";
                                        addUser.Password = "";
                                        addUser.ConfirmPassword = "";
                                        setPassword('');
                                        setValidated(false);
                                        close();
                                    }
                                }}>Cancel</Button>
                            </div>
                        </Form>
                    </div>
                )}
            </Popup>
            <Row>
                <Col>
                    <Card style={{ height: 'auto', overflowX: 'auto' }}>
                        <Table hover bordered striped responsive size="sm">
                            <thead>
                                <tr>
                                    <th className="width-100"> </th>
                                    <th className="width-100"> </th>
                                    <th>Email</th>
                                    <th>Role Name</th>
                                    <th>Username</th>
                                    <th>Id</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    users.length > 0 ?
                                        users.map((item, idx) => {
                                            return <tr>
                                                <td className="align-center">
                                                    <div className="btn-group">
                                                        <Popup
                                                            trigger={
                                                                item.userName !== "Admin" ?
                                                                    <span className="edit-icon" onClick={() => { editSelectedUser(item) }} title={"Edit"}></span>
                                                                    : null
                                                            }
                                                            modal
                                                            nested
                                                            onOpen={() => editSelectedUser(item)}
                                                            closeOnDocumentClick={false}
                                                        >
                                                            {close => (
                                                                <div className="modal" ref={editModal}>
                                                                    <button className="close" onClick={() => {

                                                                        if (window.confirm(Message.msg_close) == true) {
                                                                            //item.userName = "";
                                                                            //item.email = "";
                                                                            item.password = "";
                                                                            item.confrim_password = "";
                                                                            setEditPassword('');
                                                                            setEditValidated(false);
                                                                            close();
                                                                        }
                                                                    }}>
                                                                        &times;
                                                                    </button>
                                                                    <div className="header"> Edit User </div>
                                                                    <Form noValidate validated={editValidated} onSubmit={updateUser}>
                                                                        <div className="content" style={{ padding: 5 }}>

                                                                            <Row className="mb-3">

                                                                                <Form.Group as={Col} md="6" controlId="userName">
                                                                                    <Form.Label>User Name:</Form.Label>
                                                                                    <Form.Control
                                                                                        required
                                                                                        type="text"
                                                                                        placeholder="User Name"
                                                                                        name="userName"
                                                                                        value={user.userName}
                                                                                        onChange={(event) => { handleUserChange(event, user) }}
                                                                                    />
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md="6" controlId="email">
                                                                                    <Form.Label>Email:</Form.Label>
                                                                                    <Form.Control
                                                                                        required
                                                                                        type="text"
                                                                                        placeholder="Email"
                                                                                        name="email"
                                                                                        value={user.email}
                                                                                        onChange={(event) => { handleUserChange(event, user) }}
                                                                                    />
                                                                                </Form.Group>
                                                                            </Row>

                                                                            <Row className="mb-3">
                                                                                <Form.Group as={Col} md="6" controlId="password">
                                                                                    <Form.Label>Password:</Form.Label>
                                                                                    <Form.Control
                                                                                        className="shadow-none mb-1"
                                                                                        
                                                                                        style={{ width: '95%' }}
                                                                                        type={editPasswordShown ? "text" : "password"}
                                                                                        placeholder="Password"
                                                                                        name="password"
                                                                                        value={user.password}
                                                                                        onChange={(event) => { handleUserChange(event, user) }}
                                                                                    />
                                                                                    <i onClick={toggleEditPassword} className={`fa ${editPasswordShown ? "fa-eye-slash" : "fa-eye"}`} style={{ cursor: 'pointer', zIndex: '200', position: 'absolute', right: '20px', top: '35px', fontSize: 'large' }}></i>
                                                                                    <PasswordStrengthMeter password={editPassword} />
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md="6" controlId="confirm_Password">
                                                                                    <Form.Label>Confirm Password:</Form.Label>
                                                                                    <Form.Control
                                                                                        
                                                                                        type="password"
                                                                                        placeholder="Confirm Password"
                                                                                        name="confirm_Password"
                                                                                        value={user.confrim_Password}
                                                                                        onChange={(event) => { handleUserChange(event, user) }}
                                                                                    />
                                                                                </Form.Group>
                                                                            </Row>
                                                                        </div>

                                                                        <div className="actions" style={{ padding: 5 }}>
                                                                            {/*<Button variant="success" onClick={() => { addIshaaraat() ?? close() }}>Save</Button>*/}
                                                                            <Button type="submit" value="Save">Save</Button>
                                                                            <Button className="margin-left-10" variant="secondary" onClick={() => {

                                                                                if (window.confirm(Message.msg_close) == true) {
                                                                                    //item.userName = "";
                                                                                    //item.email = "";
                                                                                    item.password = "";
                                                                                    item.confrim_password = "";
                                                                                    setEditPassword('');
                                                                                    setEditValidated(false);
                                                                                    close();
                                                                                }
                                                                            }}>Cancel</Button>
                                                                        </div>
                                                                    </Form>

                                                                </div>
                                                            )}
                                                        </Popup>

                                                    </div>
                                                </td>
                                                <td className="align-center">
                                                    <div className="btn-group">
                                                        {item.userName !== "Admin" ?
                                                            <span className="delete-icon" onClick={() => {
                                                                if (window.confirm(Message.msg_delete_user) == true) {
                                                                    deleteUser(item);
                                                                }
                                                            }}></span>
                                                         : null }
                                                    </div>
                                                </td>
                                                <td
                                                    className="align-center"
                                                    margin="normal">{item.email}
                                                </td>
                                                <td
                                                    className="align-center"
                                                    margin="normal">{item.roleName}
                                                </td>
                                                <td
                                                    className="align-center"
                                                    margin="normal">{item.userName}
                                                </td>
                                                <td
                                                    className="align-center"
                                                    margin="normal">{idx + 1}
                                                </td>
                                            </tr>
                                        })
                                        : null
                                }
                            </tbody>
                        </Table>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default User;