import React from 'react'
import { Card, CardBody, CardHeader, Col, Pagination, PaginationItem, PaginationLink, Row, Table, Input, InputGroup, } from 'reactstrap';
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import 'react-tabs/style/react-tabs.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

import Form from 'react-bootstrap/Form';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { API_URL } from '../constant/URL';
//import "react-table/react-table.css";
import Message from '../constant/Message';
import Select from 'react-select';
//let verbId = null;
var paraNo = 0;

function Ishaaraat(props) {
    let AlviFont = props.AlviFont;
    let page_num = props.SelectedPageNum;
    let setBlocking = props.BlockUI;
    const [surah, setSurah] = useState([]);
    const [ishaaraat, setIshaaraat] = React.useState({ description: "" });
    const [ishaaraats, setIshaaraats] = React.useState({ description: "" });
    const [validated, setValidated] = useState(false);
    const [evalidated, setEValidated] = useState(false);
    const addSurah = useRef(null);
    const form = useRef(null);
    const modal = useRef(null);
    const [pointerAayaat, setPointerAayaat] = React.useState({ start_ayat: "", end_ayat: "" });
    const [pointerSurah, setPointerSurah] = React.useState({ surah_num: "" });
    const [addPointer, setAddPointer] = React.useState({ page_num: page_num, surah_num: "", start_ayat: "", end_ayat: "", description: "", para: "", lang_id: "", line_num: "" });
    useEffect(() => {
        //setAddPointer({ ...addPointer, page_num: parseInt(page_num) });
        const GetIshaaraat = async () => {
            axios.get(`${API_URL}` + 'Ishaaraat/GetIshaaraat', {
                params: {
                    pageNum: page_num
                }
            }).then((result) => {
                setIshaaraats(result.data);
                setAddPointer({ ...addPointer, para: Number(result.data[0].para) });
                paraNo = parseInt(result.data[0].para);
                setAddPointer({ ...addPointer, lang_id: parseInt(result.data[0].lang_id) });
            });
            axios(`${API_URL}` + 'Ishaaraat/GetSurah/', {
                params: {
                    pageNum: page_num
                }
            }).then((result) => {
                const key = 'num';
                const unique = [...new Map(result.data.map(item =>
                    [item[key], item])).values()];
                const options = unique.map(surah => ({
                    "value": surah.num,
                    "label": surah.arabic_name
                }))
                setSurah(options);
            });
            }            
            GetIshaaraat();
            
        }, []);

    const updateIshaaraat = () => {
        try {
            setBlocking(true);
            var pageNum = Array.isArray(ishaaraat) ? ishaaraat[0].page_num : ishaaraat.page_num
            if (Array.isArray(ishaaraat)) {
                ishaaraat[0].surah_num = pointerSurah.surah_num;

                ishaaraat[0].start_ayat = pointerAayaat.start_ayat;
                ishaaraat[0].end_ayat = pointerAayaat.end_ayat;
            }
            else {
                ishaaraat.surah_num = pointerSurah.surah_num;

                ishaaraat.start_ayat = pointerAayaat.start_ayat;
                ishaaraat.end_ayat = pointerAayaat.end_ayat;
            }
            axios.post(`${API_URL}` + 'Ishaaraat/UpdateIshaaraat/', Array.isArray(ishaaraat) ? ishaaraat[0] : ishaaraat)
                .then((result) => {
                    if (result.data)
                        toast.success('Pointer is updated successfully.');
                    else
                        toast.error('Failed to update pointer.');


                    

                    axios.get(`${API_URL}` + 'Ishaaraat/GetIshaaraat', {
                        params: {
                            pageNum: pageNum
                        }
                    }).then((result) => {
                        setIshaaraats(result.data);
                        setTimeout(function () { setBlocking(false); }, 1500);
                    });
                    //props.history.push('/aayaat/aayat-list');
                });
            //}
            // else {
            //    return false;
            // }

        } catch (e) {
            toast.error('Error: ' + e);
        }

    }

    const deleteIshaaraat = (_Ishaaraa) => {
        try {
            axios.post(`${API_URL}` + 'Ishaaraat/DeleteIshaaraa/', _Ishaaraa)
                .then((result) => {
                    if (result.data) {
                        toast.success('Pointer deleted successfully.');
                    }
                    else {
                        toast.error('Failed to delete pointer.');
                    }



                    axios.get(`${API_URL}` + 'Ishaaraat/GetIshaaraat', {
                        params: {
                            pageNum: _Ishaaraa.page_num
                        }
                    }).then((result) => {

                        setIshaaraats(result.data);
                        setAddPointer({ ...addPointer, para: Number(result.data[0].para) });
                        paraNo = parseInt(result.data[0].para);
                        setAddPointer({ ...addPointer, lang_id: parseInt(result.data[0].lang_id) });
                        setTimeout(function () { setBlocking(false); }, 1500);
                    });
                    //props.history.push('/aayaat/aayat-list');
                });
        } catch (e) {
            toast.error('Error: ' + e);
        }
    };

    const addIshaaraat = (event) => {
        try {

            event.preventDefault();
            event.stopPropagation();
            var pageNum = addPointer.page_num;
            addPointer.para = paraNo;
            const _form = event.currentTarget;
            if (_form.checkValidity() === false) {
                setValidated(true);
            }
            else if (_form.checkValidity() === true) {
                setBlocking(true);
                setValidated(false);
                form.current.reset();
                event.target.reset();
                axios.post(`${API_URL}` + 'Ishaaraat/AddIshaaraat/', addPointer)
                    .then((result) => {
                        if (result.data) {
                            toast.success('Pointer is added successfully.');
                            addSurah.current.value = '';
                            addPointer.surah_num = "";
                            addPointer.start_ayat = "";
                            addPointer.end_ayat = "";
                            addPointer.description = "";
                            //setAddPointer({ surah_num: "", start_ayat: "", end_ayat: "", description: "" });
                            hideModal();
                        }
                        else {
                            toast.error('Failed to add pointer.');
                        }


                        
                        axios.get(`${API_URL}` + 'Ishaaraat/GetIshaaraat', {
                            params: {
                                pageNum: pageNum
                            }
                        }).then((result) => {

                            setIshaaraats(result.data);
                            setAddPointer({ ...addPointer, para: Number(result.data[0].para) });
                            paraNo = parseInt(result.data[0].para);
                            setAddPointer({ ...addPointer, lang_id: parseInt(result.data[0].lang_id) });
                            setTimeout(function () { setBlocking(false); }, 1500);
                        });
                        //props.history.push('/aayaat/aayat-list');
                    });

            }
        }
        catch (e) {
            toast.error('Error: ' + e);
        }
    }

    const hideModal = () => {
        modal.current.style['display'] = 'none';
        modal.current.parentElement.style['display'] = 'none';
        modal.current.parentElement.parentElement.style['display'] = 'none';
    };




    const handleIshaaraatChange = (e, pointer) => {
        if (e.target.name === "description") {
            e.persist();
            setIshaaraat({ ...pointer, [e.target.name]: e.target.value });
        }
        else if (e.target.name === "start_ayat") {
            e.persist();
            setPointerAayaat({ ...pointer, [e.target.name]: parseInt(e.target.value) });
        }
        else if (e.target.name === "end_ayat") {
            e.persist();
            setPointerAayaat({ ...pointer, [e.target.name]: parseInt(e.target.value) });
        }
        else if (e.target.name === "surah_num") {
            e.persist();
            setPointerSurah({ ...pointer, [e.target.name]: parseInt(e.target.value) });
        }
    };

    const isNumeric = (str) => {
        if (typeof str != "string") return false // we only process strings!  
        return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
    };

    const addSurahChange = (value) => {
        if (isNumeric(value))
            setAddPointer({ ...addPointer, surah_num: parseInt(value) });
        else
            setAddPointer({ ...addPointer, surah_num: '' });
    };

    const addStartAyatChange = (value) => {
        if (isNumeric(value))
            setAddPointer({ ...addPointer, start_ayat: parseInt(value) });
        else
            setAddPointer({ ...addPointer, start_ayat: '' });
    };

    const addLineNumChange = (value) => {
        if (isNumeric(value))
            setAddPointer({ ...addPointer, line_num: parseInt(value) });
        else
            setAddPointer({ ...addPointer, line_num: '' });
    };

    const addEndAyatChange = (value) => {
        if (isNumeric(value))
            setAddPointer({ ...addPointer, end_ayat: parseInt(value) });
        else
            setAddPointer({ ...addPointer, end_ayat: '' });
    };

    const addDescription = (value) => {
        if (value)
            setAddPointer({ ...addPointer, description: value });
        else
            setAddPointer({ ...addPointer, description: '' });
    };

    function onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }


    const editRunningIshaaraat = (pointer) => {
        setIshaaraat(pointer);
        setPointerAayaat({ start_ayat: pointer.start_ayat, end_ayat: pointer.end_ayat });
        setPointerSurah({ surah_num: pointer.surah_num })
    }

    const ShowPointerModal = () => {
        setAddPointer([]);
    }
    return (
        <div>
            <div style={{ marginBottom: '10px', paddingTop: '5px' }}>
                <Popup
                    trigger={
                        <Button style={{ display: "block", marginLeft: "auto", marginRight: 0, marginBottom: 10 }} className="btn-outline-info" onClick={() => { ShowPointerModal() }}>Add Pointer</Button>
                    }
                    modal
                    nested
                    closeOnDocumentClick={false}
                >
                    {close => (
                        <div className="modal" ref={modal} id="IshaaraatModal">
                            <button className="close" id="IshaaraatModalClose" onClick={() => {
                                //console.log('modal closed ');
                                addPointer.surah_num = "";
                                addPointer.start_ayat = "";
                                addPointer.end_ayat = "";
                                addPointer.description = "";
                                setValidated(false);
                                if (window.confirm(Message.msg_close) == true) {
                                    close();
                                }
                            }}>
                                &times;
                            </button>
                            <div className="header"> Add Pointer </div>
                            <Form ref={form} noValidate validated={validated} onSubmit={addIshaaraat}>
                                <div className="content" style={{ padding: 5 }}>
                                    <Row className="mb-9">
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>Page No:</Form.Label>
                                            <Form.Control
                                                disabled
                                                value={addPointer.page_num}
                                            />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">

                                        <Form.Group as={Col} md="6" controlId="Add_surah_num">
                                            <Form.Label>Surah No:</Form.Label>
                                            <Form.Control
                                                as="select"
                                                style={AlviFont, { fontSize: 17 }}
                                                required
                                                type="text"
                                                name="Add_surah_num"
                                                placeholder="Surah No"
                                                ref={addSurah}
                                                value={addPointer.surah_num}
                                                onChange={(event) => {
                                                    addSurahChange(event.target.value)
                                                }}
                                            >
                                                <option hidden value="" selected>Select</option>
                                                {
                                                    surah.map((item, idx) => {
                                                        return <option value={item.value}>{item.label}</option>
                                                    })
                                                }
                                            </Form.Control>
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="Add_line_num">
                                            <Form.Label>Line No:</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Line Number"
                                                name="Add_line_num"
                                                value={addPointer.line_num}
                                                onChange={(event) => { addLineNumChange(event.target.value) }}
                                            />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="Add_start_ayat">
                                            <Form.Label>Start Ayat:</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="Start Ayat"
                                                name="Add_start_ayat"
                                                value={addPointer.start_ayat}
                                                onChange={(event) => { addStartAyatChange(event.target.value) }}
                                            />
                                        </Form.Group>
                                        <Form.Group as={Col} md="6" controlId="Add_end_ayat">
                                            <Form.Label>End Ayat:</Form.Label>
                                            <Form.Control
                                                required
                                                type="text"
                                                placeholder="End Ayat"
                                                name="Add_end_ayat"
                                                value={addPointer.end_ayat}
                                                onChange={(event) => { addEndAyatChange(event.target.value) }}
                                            />
                                        </Form.Group>
                                    </Row>

                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="12" controlId="Add_description">
                                            <Form.Label>Ishaaraa :</Form.Label>
                                            <Form.Control
                                                required
                                                as="textarea"
                                                placeholder="Ishaaraa"
                                                name="Add_description"
                                                value={addPointer.description}
                                                onChange={(event) => { addDescription(event.target.value) }}
                                            />
                                        </Form.Group>
                                    </Row>
                                </div>

                                <div className="actions" style={{ padding: 5 }}>
                                    {/*<Button variant="success" onClick={() => { addIshaaraat() ?? close() }}>Save</Button>*/}
                                    <Button type="submit" value="Save">Save</Button>
                                    <Button className="margin-left-10" variant="secondary" onClick={() => {
                                        //console.log('modal closed ');
                                        addPointer.surah_num = "";
                                        addPointer.start_ayat = "";
                                        addPointer.end_ayat = "";
                                        addPointer.description = "";
                                        setValidated(false);
                                        if (window.confirm(Message.msg_close) == true) {
                                            close();
                                        }
                                    }}>Cancel</Button>
                                </div>
                            </Form>
                        </div>
                    )}
                </Popup>
                <Row>
                    <Col>
                        <Card style={{ height: 'auto', overflowX: 'auto' }}>
                            <Table hover bordered striped responsive size="sm">
                                <thead>
                                    <tr>
                                        <th>Surah No</th>
                                        <th>Line No</th>
                                        <th>Start Ayat</th>
                                        <th>End Ayat</th>
                                        <th>Pointer Text</th>
                                        <th className="width-100"> </th>
                                        <th className="width-100"> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        ishaaraats.length > 0 ?
                                            ishaaraats.map((item, idx) => {
                                                return <tr>
                                                    <td
                                                        className="align-right"
                                                        margin="normal">{item.surah_num}
                                                    </td>
                                                    <td
                                                        className="align-right"
                                                        margin="normal">{item.line_num}
                                                    </td>
                                                    <td
                                                        className="align-right"
                                                        margin="normal">{item.start_ayat}
                                                    </td>
                                                    <td
                                                        className="align-right"
                                                        margin="normal">{item.end_ayat}
                                                    </td>
                                                    <td
                                                        style={AlviFont}
                                                        className="align-right"
                                                        margin="normal">{item.description}
                                                    </td>
                                                    <td className="align-center">
                                                        <div className="btn-group">
                                                            <span className="delete-icon" onClick={() => {
                                                                if (window.confirm(Message.msg_delete_pointer) == true) {
                                                                    deleteIshaaraat(item);
                                                                }
                                                            }}></span>
                                                        </div>
                                                    </td>
                                                    <td className="align-center">
                                                        <div className="btn-group">
                                                            <Popup
                                                                trigger={
                                                                    <span className="edit-icon" onClick={() => { editRunningIshaaraat(item) }} title={"Edit"} ></span>

                                                                }
                                                                modal
                                                                nested
                                                                onOpen={() => editRunningIshaaraat(item)}
                                                                closeOnDocumentClick={false}
                                                            >
                                                                {close => (
                                                                    <div className="modal">
                                                                        <button className="close" onClick={() => {
                                                                            //console.log('modal closed ');
                                                                            if (window.confirm(Message.msg_close) == true) {
                                                                                close();
                                                                            }
                                                                        }}>
                                                                            &times;
                                                                        </button>
                                                                        <div className="header"> Edit Pointer </div>

                                                                        <div className="content">
                                                                            <div className="form-row" style={{ marginBottom: "10px" }}>
                                                                                <div className="col col-md-2">
                                                                                    <label>
                                                                                        <span style={{ fontWeight: 'bold' }}>Page No: </span>
                                                                                    </label>

                                                                                </div>
                                                                                <div className="col col-md-9" style={{ fontSize: 17 }}>
                                                                                    <span>{item.page_num}</span>
                                                                                </div>
                                                                            </div>
                                                                            <div className="form-row frm-input" style={{ marginBottom: "10px" }}>
                                                                                <div className="col col-md-2">
                                                                                    <label for="surah_num">
                                                                                        <span style={{ fontWeight: 'bold' }}>Surah No: </span>
                                                                                    </label>

                                                                                </div>
                                                                                <div className="col col-md-4">
                                                                                    <select onChange={(event) => { handleIshaaraatChange(event, pointerSurah) }}
                                                                                        id="surah_num" name="surah_num" value={pointerSurah.surah_num} className="form-control" style={{ height: "50px" }}>
                                                                                        {surah.map((option) => (
                                                                                            <option value={option.value}>{option.label}</option>
                                                                                        ))}
                                                                                    </select>

                                                                                </div>
                                                                                <div className="col col-md-1 align-right">
                                                                                    <label for="line_no">
                                                                                        <span style={{ fontWeight: 'bold' }}>Line No: </span>
                                                                                    </label>

                                                                                </div>
                                                                                <div className="col col-md-4">
                                                                                    <input style={{
                                                                                        resize: "none", fontFamily: "Alvi",
                                                                                        fontSize: 24, textAlign: "right"
                                                                                    }} className="form-control" id="line_no" name="line_no" type="text" value={ishaaraat.line_num} onChange={(event) => { handleIshaaraatChange(event, ishaaraat) }} />
                                                                                </div>
                                                                          



                                                                         
                                                                            </div>

                                                                            <div className="form-row frm-input" style={{ marginBottom: "10px" }}>
                                                                                <div className="col col-md-2">
                                                                                    <label for="start_ayat">
                                                                                        <span style={{ fontWeight: 'bold' }}>Start Ayat: </span>
                                                                                    </label>

                                                                                </div>
                                                                                <div className="col col-md-4">
                                                                                    <input style={{
                                                                                        resize: "none", fontFamily: "Alvi",
                                                                                        fontSize: 24, textAlign: "right"
                                                                                    }} className="form-control" id="start_ayat" name="start_ayat" type="text" value={pointerAayaat.start_ayat} onChange={(event) => { handleIshaaraatChange(event, pointerAayaat) }} />
                                                                                </div>
                                                                                <div className="col col-md-1 align-right">
                                                                                    <label for="end_ayat">
                                                                                        <span style={{ fontWeight: 'bold' }}>End Ayat: </span>
                                                                                    </label>

                                                                                </div>
                                                                                <div className="col col-md-4">
                                                                                    <input style={{
                                                                                        resize: "none", fontFamily: "Alvi",
                                                                                        fontSize: 24, textAlign: "right"
                                                                                    }} className="form-control" id="end_ayat" name="end_ayat" type="text" value={pointerAayaat.end_ayat} onChange={(event) => { handleIshaaraatChange(event, pointerAayaat) }} />
                                                                                </div>
                                                                            </div>




                                                                            <div className="form-row" style={{ marginBottom: "10px" }}>
                                                                                <div className="col col-md-2">
                                                                                    <label for="editDescription">
                                                                                        <span style={{ fontWeight: 'bold' }}>Ishaaraa: </span>
                                                                                    </label>

                                                                                </div>
                                                                                <div className="col col-md-9">
                                                                                    <textarea style={{
                                                                                        resize: "none", fontFamily: "Alvi", fontSize: 24,
                                                                                    }} className="form-control" name="description" id="editDescription" type="text" value={ishaaraat.description} onChange={(event) => { handleIshaaraatChange(event, ishaaraat) }} />
                                                                                </div>


                                                                            </div>
                                                                        </div>
                                                                        <div className="actions">
                                                                            <Button variant="success" onClick={() => { updateIshaaraat() ?? close() }}>Save</Button>

                                                                            <Button className="margin-left-10" variant="secondary" onClick={() => {
                                                                                //console.log('modal closed ');
                                                                                if (window.confirm(Message.msg_close) == true) {
                                                                                    close();
                                                                                }
                                                                            }}>Cancel</Button>
                                                                        </div>
                                                                    </div>
                                                                )}
                                                            </Popup>

                                                        </div>
                                                    </td>
                                                </tr>
                                            })
                                            : null
                                    }
                                </tbody>
                            </Table>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>

    )
}
export default Ishaaraat;