import React, { useState } from "react";
import axios from "axios";
import { API_URL, CONTENT_URL } from '../constant/URL';
import Message from '../constant/Message';
import { toast } from 'react-toastify';

const ImageUpload = (props) => {
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    let fileType = props.fileType;
    let labelValue = props.labelValue;
    let allowedFileTypes = props.allowedFileTypes;
    let has_image = props.has_image;

    console.log("has_image:", has_image);
    console.log("props.uplodedFileName:", props.uplodedFileName);

    const saveFile = (e) => {
        console.log(": : ",e.target.files[0]);
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };

    const uploadFile = async (e) => {
        if (file != null && fileName != null) {
            const formData = new FormData();
            formData.append("formFile", file);
            formData.append("fileName", fileName);
            formData.append("fileType", fileType);
            formData.append("identifier", props.uplodedFileName);

            debugger;

            try {
                const res = await axios.post(`${API_URL}` + 'Baab', formData).then((result) => {
                    //debugger;
                    if (result.data != null && result.data.output && result.data.fileName != null) {
                        toast.success('File uploaded successfully.');
                        if (props.onUploadImage) {
                            props.onUploadImage();
                        }
                        //setUplodedFileName(result.data.fileName);
                    }
                    else {
                        toast.error('Failed to upload file.');
                    }
                });
                console.log(res);
            } catch (ex) {
                console.log(ex);
            }
        }
    };

    const removeFile = async (e) => {
        const formData = new FormData();
        formData.append("baabCode", props.uplodedFileName);
        try {
            const res = await axios.post(`${API_URL}` + 'Baab/RemoveFile', formData).then((result) => {
                debugger;
                if (result.data) {
                    toast.success(fileType + ' file removed successfully.');
                    if (props.onRemoveImage) {
                        props.onRemoveImage();
                    }
                    //setUplodedFileName("");
                }
                else {
                    toast.error(' Failed to remove ' + fileType + " file.");
                }
            });
            console.log(res);
        } catch (ex) {
            console.log(ex);
        }
    };
    let removeBtnMarkup = (<button title="Remove attachment" className="remove-btn btn-danger" type="button" onClick={() => {
        if (window.confirm(Message.msg_remove_file) == true) {
            removeFile();
        }
    }} > X</button>);
    if (props.uplodedFileName == "" || !has_image) {
        removeBtnMarkup = [];
    }
     return (
         <div className="align-left">
             <label htmlFor={"file" + fileType} className="fileLabel">
                 <span style={{ fontWeight: 'bold' }}>{labelValue + ":"}</span>
             </label>
             <br />
             <input accept={allowedFileTypes} type="file" onChange={saveFile} className="fileInput" id={"file" + fileType} />
             <input type="button" value={labelValue} onClick={uploadFile} />
             {has_image ? <a target="_blank" href={`${CONTENT_URL}` + 'uploads/' + fileType.toLowerCase() + "/" + props.uplodedFileName + ".jpg"} download><label className="attachment_name" dir="ltr">{props.uplodedFileName + ".jpg"}</label></a>:[]}
             {removeBtnMarkup}
         </div>
     );
};

export default ImageUpload;