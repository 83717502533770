import React, { useState } from "react";
import axios from "axios";
import { API_URL, CONTENT_URL } from '../constant/URL';
import Message from '../constant/Message';
import { toast } from 'react-toastify';

const FileUpload = (props) => {
    const [file, setFile] = useState();
    const [fileName, setFileName] = useState();
    let wordId = props.wordId;
    let fileType = props.fileType;
    let labelValue = props.labelValue;
    const [uplodedFileName, setUplodedFileName] = useState(props.uplodedFileName);

    console.log("FileUpload > wordId:", wordId);

    const saveFile = (e) => {
        console.log(e.target.files[0]);
        setFile(e.target.files[0]);
        setFileName(e.target.files[0].name);
    };

    const uploadFile = async (e) => {
        const formData = new FormData();
        formData.append("formFile", file);
        formData.append("fileName", fileName);
        formData.append("fileType", fileType);
        formData.append("wordId", wordId);

        try {
            const res = await axios.post(`${API_URL}` + 'Verb', formData).then((result) => {
                //debugger;
                if (result.data != null && result.data.output && result.data.fileName != null) {
                    toast.success(fileType + ' file uploaded successfully.');
                    setUplodedFileName(result.data.fileName);
                }
                else {
                    toast.error(' Failed to upload ' + fileType+" file.");
                }
            });
            console.log(res);
        } catch (ex) {
            console.log(ex);
        }
    };

    const removeFile = async (e) => {
        
        const formData = new FormData();
        formData.append("fileType", fileType);
        formData.append("wordId", wordId);

        try {
            const res = await axios.post(`${API_URL}` + 'Verb/RemoveFile', formData).then((result) => {
                debugger;
                if (result.data) {
                    toast.success(fileType + ' file removed successfully.');
                    setUplodedFileName("");
                }
                else {
                    toast.error(' Failed to remove ' + fileType + " file.");
                }
            });
            console.log(res);
        } catch (ex) {
            console.log(ex);
        }
    };
    let removeBtnMarkup = (<button title="Remove attachment" class="remove-btn btn-danger" type="button" onClick={() => {
        //console.log('modal closed ');
        if (window.confirm(Message.msg_remove_file) == true) {
            removeFile();
        }
    }} > X</button>);
    if (uplodedFileName == "") {
        removeBtnMarkup = [];
    }
     return (
         <div className="align-left">
             <label for={"file" + fileType} className="fileLabel">
                 <span style={{ fontWeight: 'bold' }}>{labelValue}</span>
             </label>
             <br />
             <input accept=".PDF, .PNG, .JPG, .JPEG, .GIF" type="file" onChange={saveFile} className="fileInput" id={"file" + fileType} />
             <input type="button" value={"Upload " + fileType + " File"} onClick={uploadFile} />
             <a target="_blank" href={`${CONTENT_URL}` + 'uploads/' + fileType.toLowerCase() + "/" + uplodedFileName} download><label class="attachment_name" dir="ltr">{uplodedFileName}</label></a>
             {removeBtnMarkup}
         </div>
     );
};

export default FileUpload;