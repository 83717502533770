import React from 'react'
import { Card, CardBody, CardHeader, Col, Pagination, PaginationItem, PaginationLink, Row, Table, Input, InputGroup } from 'reactstrap';
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import 'react-tabs/style/react-tabs.css';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { Button, Spinner } from 'react-bootstrap';
import { render } from "react-dom";
import ReactTable from '../Table/ReactTable.js';
import { toast } from 'react-toastify';
import Select from 'react-select';
import Form from 'react-bootstrap/Form';
import BlockUi from 'react-block-ui';
import 'react-block-ui/style.css';
import { API_URL, CONTENT_URL } from '../constant/URL';
import ImageUpload from '../BaabManagement/ImageUpload';

import Message from '../constant/Message';
import 'font-awesome/css/font-awesome.min.css';

function Baab(props) {
    const [blocking, setBlocking] = useState([false]);
    
    const [baab, setBaab] = React.useState([]);
    const [baabs, setBaabs] = React.useState([]);
    const [editValidated, setEditValidated] = useState(false);
    const editModal = useRef(null);
    const imageDiv = useRef(null);
    const msgDiv = useRef(null);
    console.log("baab:", baab);

    const AlMushafFont = {
        fontFamily: "AlMushaf",
        fontSize: 24
    };

    useEffect(() => {
        const GetBaabs = async () => {
            axios(`${API_URL}` + 'Baab/GetBaabs/')
                .then((result) => {
                    setBaabs(result.data);
                });

        };
        GetBaabs();
    }, []);

    

    const updateBaab = (event) => {
        try {
            event.preventDefault();
            event.stopPropagation();
            
            const _form = event.currentTarget;
            if (_form.checkValidity() === false) {
                setEditValidated(true);
            }
           
            else if (_form.checkValidity() === true) {
                setEditValidated(false);
                setBlocking(true);
                //axios.post(`${API_URL}` + 'User/UpdateUser/', user)
                //    .then((result) => {
                //        if (result.data) {
                //            toast.success('Baab is updated successfully.');
                //            baab.userName = "";
                //            baab.email = "";
                //            hideEditModal();
                //        }
                //        else {
                //            hideEditModal();
                //            toast.error('Failed to update baab.');
                //        }
                //        axios(`${API_URL}` + 'Baab/GetBaabs/')
                //            .then((result) => {
                //                setBaabs(result.data);
                //                setTimeout(function () { setBlocking(false); }, 1500);
                //        });
                //    });
            }
        } catch (e) {
            toast.error('Error: ' + e);
        }
    };

    const hideEditModal = () => {
        editModal.current.style['display'] = 'none';
        editModal.current.parentElement.style['display'] = 'none';
        editModal.current.parentElement.parentElement.style['display'] = 'none';
    };

    const editSelectedBaab = (_baab) => {
        axios(`${API_URL}` + 'Baab/HasGrammarImage?baabCode=' + _baab.baab_code )
            .then((result) => {
                console.log("result:", result);
                if (result.data.output) {
                    imageDiv.current.style['display'] = 'block';
                    msgDiv.current.style['display'] = 'none';
                    _baab.has_grammar_image = true;
                }
                else {
                    imageDiv.current.style['display'] = 'none';
                    msgDiv.current.style['display'] = 'block';
                    _baab.has_grammar_image = false;
                }
                console.log("_baab:", _baab);
                setBaab(_baab);
            });
    }

    const onRemoveImage = function () {
        setBaab({ ...baab, has_grammar_image: false });
        imageDiv.current.style['display'] = 'none';
        msgDiv.current.style['display'] = 'block';
    }

    const onUploadImage = function () {
        setBaab({ ...baab, has_grammar_image: true });
        imageDiv.current.style['display'] = 'block';
        msgDiv.current.style['display'] = 'none';
    }

    return (
        <div>
            <h2>Baab Management</h2>
            <p className="help-text">This section allows managing the baabs for the Easy Quranic Arabic application.</p>
            
            <Row>
                <Col>
                    <Card style={{ height: 'auto', overflowX: 'auto' }}>
                        <Table hover bordered striped responsive size="sm">
                            <thead>
                                <tr>
                                    <th className="width-100"> </th>
                                    <th>Traditional Baab</th>
                                    <th>Baab Name</th>
                                    <th>Baab Code</th>

                                    <th>Id</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    baabs.length > 0 ?
                                        baabs.map((item, idx) => {
                                            return <tr>
                                                <td className="align-center">
                                                    <div className="btn-group">
                                                        <Popup
                                                            trigger={
                                                                <span className="edit-icon" onClick={() => { editSelectedBaab(item) }} title={"Edit"}></span>
                                                            }
                                                            modal
                                                            nested
                                                            onOpen={() => editSelectedBaab(item)}
                                                            closeOnDocumentClick={false}
                                                        >
                                                            {close => (
                                                                <div className="modal" ref={editModal}>
                                                                    <button className="close" onClick={() => {
                                                                        setEditValidated(false);
                                                                        close();
                                                                    }}>
                                                                        &times;
                                                                    </button>
                                                                    <div className="header"> Upload Baab Image</div>
                                                                    <Form noValidate validated={editValidated} onSubmit={updateBaab}>
                                                                        <div className="content" style={{ padding: 5 }}>
                                                                            <Row className="mb-3">
                                                                                <Form.Group as={Col} md="4" controlId="email">
                                                                                    <p style={{ padding: 0, margin: 0 }}><Form.Label style={{ margin: 0, lineHeight:"36px"}}>Baab Code: {baab.baab_code}</Form.Label></p>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md="4" controlId="email">
                                                                                    <p style={{ padding: 0, margin: 0 }}><Form.Label style={{ margin: 0 }}>Baab Name: <span style={AlMushafFont}>{baab.baab_name}</span></Form.Label></p>
                                                                                </Form.Group>
                                                                                <Form.Group as={Col} md="4" controlId="email">
                                                                                    <p style={{ padding: 0, margin: 0 }}><Form.Label style={{ margin: 0 }}>Traditional Baab: <span style={AlMushafFont}>{baab.traditional_baab}</span></Form.Label></p>
                                                                                </Form.Group>
                                                                            </Row>
                                                                        </div>
                                                                        <div className="content" style={{ padding: 0, display: "none" }} ref={imageDiv}>
                                                                            {baab.baab_code && baab.has_grammar_image ? <img src={`${CONTENT_URL}` + "uploads/grammar_images/" + baab.baab_code + ".jpg"} width="300px" /> : []}
                                                                        </div>
                                                                        <div className="msg-div"  style={{ display: "none" }} ref={msgDiv}>
                                                                            No grammar/baab image exists, please select an image an upload a *.jpg file.
                                                                        </div>
                                                                        <div className="actions" style={{ padding: 5 }}>
                                                                            {baab.baab_code ? <ImageUpload fileType={"grammar_images"} labelValue="Upload Baab Image" uplodedFileName={baab.baab_code} allowedFileTypes={".JPG, .JPEG"} has_image={baab.has_grammar_image} onRemoveImage={onRemoveImage} onUploadImage={onUploadImage} /> : []}
                                                                        </div>
                                                                    </Form>

                                                                </div>
                                                            )}
                                                        </Popup>

                                                    </div>
                                                </td>
                                                <td
                                                    className="align-center"
                                                    margin="normal">
                                                    <span style={AlMushafFont}>{item.traditional_baab}</span>
                                                </td>
                                                <td
                                                    className="align-center"
                                                    margin="normal"><span style={AlMushafFont}>{item.baab_name}</span>
                                                </td>
                                                <td
                                                    className="align-center"
                                                    margin="normal">{item.baab_code}
                                                </td>
                                                <td
                                                    className="align-center"
                                                    margin="normal">{item.id}
                                                </td>
                                            </tr>
                                        })
                                        : null
                                }
                            </tbody>
                        </Table>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Baab;