import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;
  render () {
    return (
      <div>
       {/* <h1>Welcome to Quran Admin Site!</h1>*/}
      </div>
    );
  }
}
