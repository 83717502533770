import React, { useState } from 'react';

import axios from 'axios';
//import { useState, useEffect } from 'react';
import { ContextMenuTrigger, ContextMenu, MenuItem } from "react-contextmenu";
import EqaHelper from '../Helper/EqaHelper';

let selectedText;

function EqaContextMenu(props) {
    //alert("Inside EqaContextMenu:" + props.sub_words);
    //const GetMenuItems = async () => {
    //    axios('https://localhost:44304/api/Aayaat/GetPages/')
    //        .then((result) => {
    //            //const options = result.data.map(pagenum => ({
    //            //    "value": pagenum,
    //            //    "label": pagenum
    //            //}))
    //            //setPages(options);
    //            //getAyaatList(result.data[0]);
    //        });

    //};

    //$('.right-clickable').mousedown(function (e) {
    //    $('.right-clickable').css({ background: '', color: '' });
    //    if (e.which == 3) {
    //        endCellId = $(this).attr('id');
    //        wordId = endCellId;
    //        surahOfWord = $(this).attr('surah');
    //        //highlightedText = $(this).text();
    //        $(this).closest('div').css({ background: '#C5ECF1' });
    //        startCellId = $(this).attr('id');
    //        $(".category-right-click-menu").hide(100);

    //    }
    //});

    const [selected, setSelected] = useState(false);

    const getSelectionText = function (element) {
        var text = "";
        let startOffset = 0;
        let endOffset = 0;
        if (window.getSelection) {
            text = window.getSelection().toString();
            var range = window.getSelection().getRangeAt(0);
            var preCaretRange = range.cloneRange();
            preCaretRange.selectNodeContents(element);
            preCaretRange.setEnd(range.startContainer, range.startOffset);
            startOffset = preCaretRange.toString().length;
            endOffset = startOffset + range.toString().length;

        } else if (document.selection && document.selection.type !== "Control") {
            text = document.selection.createRange().text;

            var textRange = document.selection.createRange();
            var preCaretTextRange = document.body.createTextRange();
            preCaretTextRange.moveToElementText(element);
            preCaretTextRange.setEndPoint("EndToStart", textRange);
            startOffset = preCaretTextRange.text.length;
            endOffset = startOffset + textRange.text.length;
        }
        return {
            text: text, startOffset: startOffset, endOffset: endOffset
        };
    }
    const onRightClick = function(e, data) {
        //alert(data.foo);
        //setSelected(true);
        //props.sub_words = '{"word": "اَلْ" , "id":"1", "category": "afaal"},{  "category": "asma", "word":"حَمْدُ" , "id":"2"}';
        if (selectedText != null)//TODO
        {
            let subWords = data.category != "default" ? EqaHelper.getSubWords(data.category, props.text, props.sub_words, selectedText.text, selectedText.startOffset, selectedText.endOffset-1) : null;
            let subWordsStr = null;
            if (subWords != null) {
                subWordsStr = JSON.stringify(subWords);
                subWordsStr = subWordsStr.substring(1, subWordsStr.length - 1);
                console.log("onRightClick:", subWordsStr);
            }
            props.onSubWordsModelUpdate(subWordsStr);//TODO 
        }
        //debugger;
        //let selectedText = getSelectionText(data.target.parentElement);

    };

    const showMenu = function () {
        setSelected(true);
    };

    const hideMenu = function () {
        setSelected(false);
    };

    const handleMouseUp = function (ev) {
        
        if (ev.buttons == 0) {
            //console.log("handleMouseUp > ev.button:" + ev.button);
            selectedText = getSelectionText(ev.currentTarget); 
            //console.log("handleMouseUp > selectedText:", selectedText);
        }
        //let selectedText = getSelectionText(data.target.parentElement);
    }

    const handleMouseDown = function (ev) {
        //setSelected(false);
        if (ev.button == 2) {
            //setSelected(true);
            console.log("handleMouseMove > ev.button:" + ev.button);
        }
    }

    //useEffect(() => {
    //    //GetMenuItems();
    //}, []);
    
    return (
        
        props.mode != "review" ?
            <div>
                <ContextMenuTrigger id={"same_unique_identifier" + props.id} >
                    <span onMouseUp={(ev) => handleMouseUp(ev)}
                        onMouseDown={(ev) => handleMouseDown(ev)}
                        className="well"
                        style={selected ? { backgroundColor: "#C5ECF1" } : {}}>
                        <span dangerouslySetInnerHTML={{ __html: EqaHelper.createMarkup(props.text, props.sub_words) }} />
                    </span>
                    {/*<br/>*/}
                    {/*{<span>{props.sub_words}</span>}*/}
                </ContextMenuTrigger>
                <ContextMenu id={"same_unique_identifier" + props.id} onShow={showMenu} onHide={hideMenu}>
                    <MenuItem data={{ category: 'afaal' }} onClick={onRightClick} className="context-menu colorAfaal">
                        افعال
                    </MenuItem>
                    <MenuItem data={{ category: 'asma' }} onClick={onRightClick} className="context-menu colorAsma">
                        اسماء
                    </MenuItem>
                    <MenuItem data={{ category: 'faael' }} onClick={onRightClick} className="context-menu colorFaael">
                        فاعل
                    </MenuItem>
                    <MenuItem data={{ category: 'mafool' }} onClick={onRightClick} className="context-menu colorMafool">
                        مفعول
                    </MenuItem>
                    <MenuItem data={{ category: 'haal' }} onClick={onRightClick} className="context-menu colorHaal">
                        حال
                    </MenuItem>
                    <MenuItem data={{ category: 'sift' }} onClick={onRightClick} className="context-menu colorSift">
                        صفت
                    </MenuItem>
                    <MenuItem data={{ category: 'tameeaz' }} onClick={onRightClick} className="context-menu colorTamyuz">
                        تمییز
                    </MenuItem>
                    <MenuItem data={{ category: 'zaraf' }} onClick={onRightClick} className="context-menu colorZarf">
                        ظرف
                    </MenuItem>
                    <MenuItem data={{ category: 'takeed' }} onClick={onRightClick} className="context-menu colorTakeed">
                        تأكيد
                    </MenuItem>
                    <MenuItem data={{ category: 'zameer_shaan' }} onClick={onRightClick} className="context-menu colorZameerShaan">
                        ضمير شان
                    </MenuItem>
                    <MenuItem data={{ category: 'ism_fael' }} onClick={onRightClick} className="context-menu colorIsmFel">
                        اسم فعل
                    </MenuItem>
                    <MenuItem divider />
                    <MenuItem className="react-contextmenu-special-item" data={{ category: 'default' }} onClick={onRightClick}>
                        Remove Colors
                    </MenuItem>
                    {/*<MenuItem className="react-contextmenu-special-item" data={{ foo: 'bar13' }} onClick={onRightClick}>*/}
                    {/*    Edit Sarf*/}
                    {/*</MenuItem>*/}
                </ContextMenu>


            </div>
            :
            <span dangerouslySetInnerHTML={{ __html: EqaHelper.createMarkup(props.text, props.sub_words) }} />
    )
}
export default EqaContextMenu;