import React from "react";
import { useTable } from "react-table";

export default function ReactTable({ columns, data, setWordGrammerData, updatedWordsData, isReadonly, color, hideHeader
    //setupdatedData 
}) {
    const [originalData] = React.useState(data)
    const EditableCell = ({
        value: initialValue,
        row: { index },
        column: { id },
      
    }) => {
        const [value, setValue] = React.useState(initialValue)
        const onChange = (e) => {
            const fieldName = e.target.getAttribute("name");
            const fieldValue = e.target.value;
            const newData = new Array();
            newData.push(updatedWordsData[0]);
            newData[0][fieldName] = fieldValue;
           // newData[0].is_grammer_data = true;
            //setupdatedData(newData);
            setWordGrammerData(newData);
        }
        React.useEffect(() => {
            setValue(initialValue)
        }, [initialValue])
        return id != "id" ? <input disabled={isReadonly ? true : false} value={value} name={id} onChange={onChange}  /> : <input value={value} type="hidden" />;
    }
    const defaultColumn = {
        Cell: EditableCell,
    }
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
        defaultColumn
    })
    return (
        <div style={{ overflow:'auto' }}>
            <table {...getTableProps()}>
                {hideHeader !== null && hideHeader == true ? <></> : <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()} className={color ? color : ""}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>}
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps()} className={color?color:"" }>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                              
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div >
    );
};
