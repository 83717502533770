const Message = {
    msg_close: "Are you sure you want to close this dialog without saving information?",
    msg_remove_file: "Are you sure you want to delete this file?",
    msg_delete_pointer: "Are you sure you want to delete the pointer?",
    msg_delete_user: "Are you sure you want to delete the user?",
    msg_update_all: "Are you sure you want to save the information for all similar count words?",
    msg_update_all_without_count: "Are you sure you want to save the information for all similar words?",
    msg_invalid_email: "Email entered is not correct.",
    msg_invalid_password: "Password entered is too weak.",
    msg_invalid_confirm_password: "Password entered does not match confirm password.",
};

export default Message;