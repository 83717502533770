import React, { useState } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Home } from './components/Home';
import { Layout } from './components/Layout';
import Login from './components/Login/Login';
import SignUp from './components/Login/SignUp';
import Logout from './components/Login/Logout';
import AayatList from './components/Aayaat/AayatList';
import Users from './components/UserManagement/User';
import Baab from './components/BaabManagement/Baab';
import {ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import ProtectedRoute from './components/Route/ProtectedRoute';
import AuthContext from './components/Login/AuthContext';
import './custom.css'
import { API_URL } from './components/constant/URL';
import ReviewList from './components/Aayaat/ReviewList';
//import PendingList from './components/Aayaat/PendingList';
function App() {
    const { token, setToken } = AuthContext();
    
    const displayName = App.name;
    if (token.email==undefined && token.email == null) {
        return (<Layout token={token} >
            <ToastContainer autoClose={5000} />
            <Login setToken={setToken} apiurl={API_URL} />
        </Layout>);
    }
    //if (SignUptoken.Succeeded) {
    //    return (<Layout token={token} >
    //        <SignUp SignUptoken={setSignUpToken} />
    //    </Layout>);
    //}
    return (
        <Layout token={token} >
            <ToastContainer autoClose={5000} />
            <Route path='/' component={Home} apiurl={API_URL}/>
            <Route path='/SignUp' component={SignUp} apiurl={API_URL}/>
            <Route exact path="/" render={() => (<Redirect to="/aayaat/aayat-list" />)} apiurl={API_URL} />
            {/*<Route exact path="/UserManagement/User" render={() => (<Redirect to="/UserManagement/User" />)} apiurl={API_URL} />*/}
            {/*<Route path='/aayaat/aayat-list' component={AayatList} />*/}
            <Route
                path='/aayaat/aayat-list'
                render={(props) => <AayatList {...props} mode={'normal'} />}
            />
            <Route
                path='/aayaat/my-edits'
                render={(props) => <AayatList {...props} mode={'pending'} />}
            />
            <Route path='/aayaat/review-list' component={ReviewList} />
            {/*<Route path='/aayaat/pending-list' component={PendingList} />*/}
            <Route path='/UserManagement/User' component={Users} />
            <Route path='/BaabManagement/Baab' component={Baab} />
        </Layout>
    );
}

export default App;
