import React from 'react';

function NahvLegend(props) {
    return (
        <div id="categories-legend" >
            <div>
                <div className="nahv-legend colorAfaal" >
                    <div>
                        <span>افعال</span>
                    </div>
                </div>
                <div className="nahv-legend colorAsma" >
                    <div>
                        <span>اسماء</span>
                    </div>
                </div>
                <div className="nahv-legend colorFaael">
                    <div>
                        <span>فاعل</span>
                    </div>
                </div>
                <div className="nahv-legend colorMafool" >
                    <div>
                        <span>مفعول</span>
                    </div>
                </div>
                <div className="nahv-legend colorHaal" >
                    <div>
                        <span>حال</span>
                    </div>
                </div>
                <div className="nahv-legend colorSift" >
                    <div>
                        <span>صفت</span>
                    </div>
                </div>
                <div className="nahv-legend colorTamyuz">
                    <div>
                        <span>تمییز</span>
                    </div>
                </div>
                <div className="nahv-legend colorZarf">
                    <div>
                        <span>ظرف</span>
                    </div>
                </div>
                <div className="nahv-legend colorTakeed">
                    < div>
                        <span>تأكيد</span>
                    </div>
                </div >
                <div className="nahv-legend colorZameerShaan">
                    <div>
                        <span>ضمير شان</span>
                    </div >
                </div >
                <div className="nahv-legend colorIsmFel">
                    <div >
                        <span>اسم فعل</span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default NahvLegend;