import React, { useState, Component } from 'react';
import { Redirect } from 'react-router-dom';
import axios from 'axios';
import { Button, Card, CardBody, CardGroup, Col, Container, Form, Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import PropTypes from 'prop-types';
import { NavMenu } from '../NavMenu';
import { toast } from 'react-toastify';
import { API_URL } from '../constant/URL';
async function loginUser(objLogin) {
    return axios.post(`${API_URL}` + 'Account/Login/', objLogin
    ).then(result => result.data);
}
export default function Login({ setToken }) {
    const [Email, setUserName] = useState();
    const [Password, setPassword] = useState();
    const handleSubmit = async e => {
        e.preventDefault();
        const token = await loginUser({
            Email,
            Password
        });
        setToken(token);
        if (!token){
            toast.error('Incorrect User Name and Password');
        }
        else{
            toast.success('Logged in successfully!');
            return <Redirect to='/surah/surah-list' />
        }
    }
    return (
        <div className="app flex-row align-items-center" >
            <Container >
                <Row className="justify-content-center" >
                    <Col md="9" lg="7" xl="6" >
                        <CardGroup>
                            <Card className="p-2" >
                                <CardBody>
                                    <Form onSubmit={handleSubmit}>
                                        <div className="row" className="mb-2 pageheading" style={{ textAlign: 'center' }}>
                                            <h2> Login</h2>
                                        </div>
                                        <InputGroup className="mb-3" >
                                            <Input name="email" type="text" required onChange={e => setUserName(e.target.value)} placeholder="Enter Email" />
                                        </InputGroup >
                                        <InputGroup className="mb-4" >
                                            <Input name="password" type="password" required onChange={e => setPassword(e.target.value)} placeholder="Enter Password" />
                                        </InputGroup >
                                        <Button color="success" block > Login</Button >
                                    </Form >
                                </CardBody >
                            </Card >
                        </CardGroup >
                    </Col >
                </Row >
            </Container >
        </div >
    )
}

Login.propTypes = {
    setToken: PropTypes.func.isRequired
}